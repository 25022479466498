@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
@charset "UTF-8";
.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #adadad;
  border-radius: 0.25rem; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #676a70;
  border-radius: 5px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4B4D53; }

.react-confirm-alert {
  border-top: 5px solid #ffa600;
  opacity: 1; }
  .react-confirm-alert .react-confirm-alert-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .react-confirm-alert .react-confirm-alert-body h1 {
      font-size: 1.3rem;
      color: #ffa600; }

a[href^="http://maps.google.com/maps"] {
  display: none !important; }

a[href^="https://maps.google.com/maps"] {
  display: none !important; }

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none; }

.react-autosuggest__suggestions-container--open {
  width: 100%;
  padding: 0 1.25rem; }

.react-autosuggest__input {
  width: 100%;
  padding: 10px 1.25rem; }

div.ReactTags__tags {
  position: relative; }

/* Styles for the input */
div.ReactTags__tagInput {
  width: 90px;
  margin: 0.125rem 5px;
  border-radius: 0.125rem;
  display: inline-block; }

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 0.25rem; }

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0.125rem 5px;
  cursor: move;
  border-radius: 0.125rem; }

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer; }

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute; }

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px; }

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0; }

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer; }

.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 22px;
  line-height: 1.25rem;
  vertical-align: middle;
  border-radius: 1.25rem 1.25rem;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1); }

.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px; }

.rc-switch:after {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0.125rem;
  top: 1px;
  border-radius: 50% 50%;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  box-shadow: 0 0.125rem 5px rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: rcSwitchOff;
  animation-name: rcSwitchOff; }

.rc-switch:hover:after {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-animation-name: rcSwitchOn;
  animation-name: rcSwitchOn; }

.rc-switch:focus {
  box-shadow: 0 0 0 0.125rem #d5f1fd;
  outline: none; }

.rc-switch-checked {
  border: 1px solid #87d068;
  background-color: #87d068; }

.rc-switch-checked .rc-switch-inner {
  left: 0.375rem; }

.rc-switch-checked:after {
  left: 22px; }

.rc-switch-disabled {
  cursor: no-drop;
  background: #ccc;
  border-color: #ccc; }

.rc-switch-disabled:after {
  background: #9e9e9e;
  -webkit-animation-name: none;
  animation-name: none;
  cursor: no-drop; }

.rc-switch-disabled:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation-name: none;
  animation-name: none; }

.rc-switch-label {
  display: inline-block;
  line-height: 1.25rem;
  font-size: 0.875rem;
  padding-left: 10px;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text; }

.react-datepicker {
  z-index: 20; }

.react-datepicker__input-container input {
  padding: 0.1rem 0.5rem; }

@-webkit-keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25); }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); } }

@keyframes rcSwitchOn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25); }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); } }

@-webkit-keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes rcSwitchOff {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.three-dot-loader {
  margin: 2rem auto 0;
  width: 10rem;
  text-align: center; }
  .three-dot-loader div {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    background-color: #637DD7;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .three-dot-loader .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .three-dot-loader .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.6); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.page-wrap {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center; }

form {
  background: white;
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 1em; }
  form h1 {
    font-size: 1.6em;
    font-weight: 300;
    margin: 0 0 1em 0;
    text-align: center; }
  form h2 {
    font-size: 1.3em;
    font-weight: 300;
    margin: 0 0 1em 0; }
  form .submit-button {
    margin-bottom: 1em; }
    form .submit-button i, form .submit-button svg {
      margin-left: 1em; }
  form input {
    margin-bottom: 1em; }
  form input, form label {
    display: block; }

.modal-body {
  overflow: auto;
  max-height: 80vh; }

.login {
  width: 99.98%;
  height: 99.98%;
  display: flex;
  align-items: center;
  background-color: white; }
  .login .left {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-left: 3rem; }
    .login .left .login-product-logo {
      height: 5rem;
      margin-bottom: 2rem;
      align-self: flex-start; }
    .login .left .login-product-header {
      text-align: left;
      font: Bold 32px/39px Proxima Nova;
      letter-spacing: 3.21px;
      color: #000A47;
      opacity: 1;
      margin-bottom: 2rem; }
    .login .left .login-textbox {
      font-size: 1rem;
      padding: 0.8rem;
      border-radius: 0.25rem;
      border: 1.5px solid gray; }
      .login .left .login-textbox:focus {
        border-color: #2684ff; }
  .login .right {
    width: 102vh;
    height: 99vh; }
  .login .footer {
    position: absolute;
    bottom: 2rem;
    left: 4rem;
    font-size: 0.8rem; }

.login-form {
  width: 100%;
  height: 100%; }
  .login-form input {
    margin-bottom: 1.8rem; }
  .login-form .privacy-notice {
    font-size: 0.9rem;
    display: block;
    align-items: center;
    margin-bottom: 0.5rem; }
    .login-form .privacy-notice .privacy-notice-icon {
      margin-right: 0.5rem;
      font-size: 1rem;
      color: gray; }
    .login-form .privacy-notice .view-privacy {
      margin-left: 0.5rem;
      cursor: pointer;
      color: #637DD7;
      display: inline-block; }
  .login-form .instructions {
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
    font-size: .875rem;
    margin-top: 0; }
  .login-form .forgot-password {
    align-self: flex-start;
    font-size: .875rem;
    background: none;
    border: none;
    color: #637DD7;
    margin-top: 1rem;
    cursor: pointer; }
  .login-form .login-button {
    background-color: #F57467 !important; }
    .login-form .login-button:hover {
      background-color: #c0463b !important; }

.invalid-login {
  color: black;
  background-color: #DE7A83;
  padding: .8em;
  margin-bottom: 1em;
  border-radius: 4px;
  vertical-align: middle;
  text-align: center; }
  .invalid-login i, .invalid-login svg {
    margin-right: 1em; }

.form-notification {
  display: flex;
  align-items: center;
  color: black;
  padding: .5em;
  margin-bottom: .3em;
  border-radius: 4px;
  vertical-align: middle;
  text-align: center; }
  .form-notification.notification-error {
    background-color: #DE7A83; }
  .form-notification.notification-warning {
    background-color: #ffa600; }
  .form-notification.notification-success {
    background-color: #61B38E; }
  .form-notification svg {
    margin-right: 1em; }

.common-textbox {
  font-size: 1em;
  padding: 0 0.5rem;
  border-color: #cccccc;
  border-radius: 0.25rem;
  border-style: solid;
  min-width: 250px;
  min-height: 38px;
  border-width: 1px; }
  .common-textbox:focus {
    border-color: #2684ff; }

.number-input {
  font-size: 1em;
  padding: 0 0.5rem;
  border: none;
  border-width: 1px;
  border-color: #cccccc;
  border-radius: 0.25rem;
  border-style: solid;
  border-color: #2684ff; }

button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none; }
  button:active {
    outline: none !important; }
  button:focus {
    outline: none !important; }

.tiny-button {
  color: #2196f3 !important;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer; }
  .tiny-button:hover {
    text-decoration: underline; }
  .tiny-button:disabled {
    color: #b1b1b1 !important;
    cursor: not-allowed; }
  .tiny-button.download {
    font-size: .8em; }
    .tiny-button.download svg {
      margin-right: .3em; }

.primary-button {
  background: none;
  font-size: inherit;
  padding: .2rem 1rem;
  box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
  border: none;
  font-weight: 300;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  background-color: #637DD7;
  transition: background-color .1s linear;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .primary-button:hover {
    background-color: #8c9fe1;
    transition: background-color .1s linear;
    text-decoration: none; }
  .primary-button.error {
    background-color: #DE7A83; }
    .primary-button.error:hover {
      background-color: #e8a3a9; }
  .primary-button:disabled {
    background-color: grey;
    cursor: not-allowed; }
  .primary-button.lg {
    font-size: 1.5rem;
    padding: 0.5rem 2rem; }

.secondary-button {
  background: none;
  font-size: inherit;
  padding: .5em;
  box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
  border: none;
  font-weight: 300;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  background-color: #525252;
  transition: background-color .1s linear; }
  .secondary-button:hover {
    background-color: #6c6c6c;
    transition: background-color .1s linear;
    text-decoration: none; }
  .secondary-button:disabled {
    background-color: grey;
    cursor: not-allowed; }

.tertiary-button {
  background: none;
  background-color: transparent;
  font-size: inherit;
  padding: .5em;
  border: 0.125rem solid #007AFF;
  font-weight: 300;
  border-radius: 0.25rem;
  cursor: pointer;
  box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3); }
  .tertiary-button:disabled {
    border-color: lightgrey;
    cursor: not-allowed; }

.close-button {
  color: #2196f3;
  background: none;
  border: none;
  cursor: pointer; }
  .close-button:hover {
    text-decoration: underline; }

.button-group {
  display: flex;
  width: 250px;
  border-radius: 0.25rem;
  box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3); }
  .button-group.long {
    width: 200px; }
  .button-group .individual-button-group {
    font-weight: 300;
    flex-grow: 1;
    background: none;
    border: none;
    padding: .5em;
    cursor: pointer;
    transition: background-color .1s linear; }
    .button-group .individual-button-group.is-selected {
      background-color: #525252;
      color: white;
      transition: background-color .1s linear;
      cursor: default; }
      .button-group .individual-button-group.is-selected:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem; }
      .button-group .individual-button-group.is-selected:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; }
  .button-group :not(:last-child) {
    border-right: 1px solid; }

.progresss-bar-wrapper {
  width: 100%;
  display: flex; }
  .progresss-bar-wrapper .icon {
    color: #DE7A83;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    transition: color 0.4s ease 0s;
    cursor: pointer; }
    .progresss-bar-wrapper .icon.success-complete {
      color: #61B38E; }
    .progresss-bar-wrapper .icon.success-fail {
      color: #DE7A83; }
    .progresss-bar-wrapper .icon.pause {
      color: #DE7A83; }
    .progresss-bar-wrapper .icon.resume {
      color: #61B38E; }

.progressbar {
  overflow: hidden;
  flex: 1 1;
  padding-top: 0.25rem;
  position: relative; }
  .progressbar .bar {
    background: rgba(0, 0, 0, 0.075); }
  .progressbar .progress-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; }
  .progressbar .progress {
    color: #fff;
    padding: 0.125rem;
    transition: width 0.3s ease 0s; }
    .progressbar .progress.active {
      background: transparent linear-gradient(325deg, #40C1AD 0%, #40C1AD 1%, #44DA9D 100%) 0% 0% no-repeat padding-box; }

.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.project-container {
  display: flex;
  max-width: 100%; }
  .project-container .list-container-default .project-card {
    margin-bottom: 30px; }
  .project-container .project-container-right {
    flex: 1 1; }
    .project-container .project-container-right .pagination {
      padding-right: 4rem; }
  .project-container .workspace-pagination-padding .pagination {
    padding-right: 10rem; }

.main-grid {
  display: grid;
  grid-template-columns: 1fr; }
  .main-grid .grid-item2 {
    margin-top: 10rem; }
    .main-grid .grid-item2 .workspace-page-img {
      width: 275px; }
  .main-grid .projects .sidebar {
    display: flex;
    position: relative;
    flex-direction: column;
    background: white;
    height: 100%; }
    .main-grid .projects .sidebar .sidebar-close {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 1rem;
      margin-top: 1rem;
      background-color: #e9f4ff; }
    .main-grid .projects .sidebar .sidebar-title {
      justify-self: center;
      align-self: center;
      padding: 2rem 0;
      background-color: #e9f4ff;
      width: 100%;
      text-align: center;
      color: #657889;
      font-weight: inherit !important; }
    .main-grid .projects .sidebar .sidebar-content {
      padding: 0 20px 20px 0px; }
      .main-grid .projects .sidebar .sidebar-content .sidebar-button {
        display: block;
        padding: 0.5rem;
        margin: 0.5rem;
        color: #657889;
        border-radius: 2rem; }
        .main-grid .projects .sidebar .sidebar-content .sidebar-button:hover {
          background-color: #657889;
          color: #ffffff; }
      .main-grid .projects .sidebar .sidebar-content .sidebar-icon {
        position: relative;
        background-color: #657889;
        color: #ffffff;
        border-radius: 50%;
        padding: 0.3rem;
        top: 2px;
        font-size: 1.7rem !important;
        width: 1.7rem !important; }
    .main-grid .projects .sidebar .sidebar-text {
      padding: 0 20px 20px 20px; }
      .main-grid .projects .sidebar .sidebar-text .collab-icon {
        background-color: #ffffff;
        color: #0076c0; }
      .main-grid .projects .sidebar .sidebar-text .collab-button {
        margin-bottom: 20px; }
        .main-grid .projects .sidebar .sidebar-text .collab-button:hover {
          color: #0076c0; }
        .main-grid .projects .sidebar .sidebar-text .collab-button:active {
          outline: none !important; }
        .main-grid .projects .sidebar .sidebar-text .collab-button:focus {
          outline: none !important; }
      .main-grid .projects .sidebar .sidebar-text .picky__input {
        border: none !important;
        margin-top: 0.4rem; }
        .main-grid .projects .sidebar .sidebar-text .picky__input:active {
          outline: none !important; }
        .main-grid .projects .sidebar .sidebar-text .picky__input:focus {
          outline: none !important; }
      .main-grid .projects .sidebar .sidebar-text .collab-submit {
        text-align: right;
        justify-items: center;
        margin-top: 10px; }
        .main-grid .projects .sidebar .sidebar-text .collab-submit .primary-button {
          margin-top: 0.5rem;
          background-color: #007af4;
          color: #ffffff;
          margin-right: 16px; }
          .main-grid .projects .sidebar .sidebar-text .collab-submit .primary-button:hover {
            background-color: #0f87ff; }
      .main-grid .projects .sidebar .sidebar-text .edit-description-icon:hover {
        cursor: pointer;
        color: red; }
      .main-grid .projects .sidebar .sidebar-text .edit-description-icon:active {
        outline: none !important; }
      .main-grid .projects .sidebar .sidebar-text .edit-description-icon:focus {
        outline: none !important; }
      .main-grid .projects .sidebar .sidebar-text .no-outline {
        width: 100%;
        margin-bottom: 0.5rem; }
        .main-grid .projects .sidebar .sidebar-text .no-outline:active {
          outline: none !important; }
        .main-grid .projects .sidebar .sidebar-text .no-outline:focus {
          outline: none !important; }
    .main-grid .projects .sidebar .collabs {
      list-style: none;
      padding-bottom: 32px;
      padding-top: 4px; }
      .main-grid .projects .sidebar .collabs li {
        padding: 6px 16px 0 16px;
        margin-left: -2rem;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .main-grid .projects .sidebar .collabs .collab-remove-icon:hover {
        color: red; }
  .main-grid .projects .main-content {
    flex: 1 1;
    flex-direction: column;
    background: inherit;
    overflow: hidden;
    margin: 32px 0 0 32px; }
  .main-grid .projects .bm-menu-wrap {
    position: fixed;
    height: 100%;
    border: 3px solid #efefef;
    top: 0; }
    @media screen and (min-width: 1200px) {
      .main-grid .projects .bm-menu-wrap {
        width: 25% !important; } }
    .main-grid .projects .bm-menu-wrap .bm-menu {
      padding: 0; }
      .main-grid .projects .bm-menu-wrap .bm-menu .sidebar-text {
        padding-bottom: 6rem; }
        .main-grid .projects .bm-menu-wrap .bm-menu .sidebar-text .picky__dropdown:last-child {
          padding-bottom: 10rem; }
  .main-grid .bold {
    font-weight: bold; }

.project-card {
  border: 0.1rem solid #dadadb;
  background-color: white;
  border-radius: 1rem;
  color: #363636;
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  box-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2);
  width: 16rem; }
  .project-card:hover {
    box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    background-color: #e9f4ff;
    cursor: pointer; }

#inputSearch {
  font-weight: bold; }

.project-selector {
  margin-top: 2rem; }

.project-card-top {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: 0 0 0 0 !important;
  height: 100px;
  border-bottom: 0.1rem solid #dadadb;
  font-weight: 500 !important; }
  .project-card-top .workspace-card-icon {
    width: 50px;
    height: 60px;
    padding-bottom: 20px; }
  .project-card-top .project-card-icon {
    width: 37px;
    height: 37px;
    margin-bottom: 20px; }
  .project-card-top .enter-icon-container {
    display: inline-block;
    align-self: flex-end;
    margin: 0;
    padding: 10px 10px 15px 15px;
    color: #646464;
    z-index: 0; }
    .project-card-top .enter-icon-container:hover {
      color: #007AFF;
      -webkit-transform: scale(1.25);
              transform: scale(1.25); }
  .project-card-top .reset-margin {
    margin: -40px 1rem !important; }
  .project-card-top__text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 14rem;
    margin: 0 0rem 1rem 0 !important;
    font-weight: 600; }
  .project-card-top .card-desc {
    padding-top: 1rem;
    text-align: right;
    color: #0077ff; }

.project-card-bottom {
  height: 7rem;
  padding: 1.5rem 0rem 0rem 0rem; }
  .project-card-bottom .card-desc {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important; }

.project-card-bottom-buttons {
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 0rem 1rem 0rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
  white-space: nowrap !important;
  text-overflow: ellipsis !important; }

.project-card-bottom-row {
  display: inline;
  text-align: center;
  border-radius: 2rem;
  background-color: rgba(120, 179, 247, 0.5);
  padding: 0.2rem 0.6rem;
  margin: 0.5rem !important;
  color: #0077ff;
  vertical-align: middle; }

.addcard {
  width: 16rem;
  box-shadow: none;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a7a9ae;
  margin-right: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  background: #fafafa 0% 0% no-repeat padding-box; }

.project-headers {
  color: #0076c0;
  font-weight: bold;
  margin: 1rem 0; }

.hidden {
  display: none !important; }

.view-all {
  width: 100%;
  text-align: center;
  color: #007af4; }

.projects-page-empty-workspace {
  display: flex;
  justify-content: space-around;
  width: 100%; }
  .projects-page-empty-workspace .greeting-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 6rem; }
    .projects-page-empty-workspace .greeting-container .greeting {
      color: #2c7ebb; }
    .projects-page-empty-workspace .greeting-container .first-workspace {
      font-size: 1.5rem;
      letter-spacing: 0;
      color: #474747; }

.singleline-message {
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
  font-size: 0.8rem; }
  .singleline-message .message-icon {
    margin-right: 0.5rem; }
  .singleline-message-lg {
    font-size: 1.1rem; }

.table-default {
  display: block;
  width: 100%;
  margin-top: 1rem;
  background-color: white; }
  .table-default .table-wrap {
    display: block;
    max-width: 99.9%;
    text-align: center; }
    .table-default .table-wrap table {
      width: 99.7%;
      margin-bottom: 0.1rem;
      border-spacing: 0; }
      .table-default .table-wrap table tr {
        border-bottom: 1px solid gray; }
        .table-default .table-wrap table tr:hover td {
          background-color: rgba(99, 125, 215, 0.05); }
      .table-default .table-wrap table th,
      .table-default .table-wrap table td {
        margin: 0;
        padding: 0.8rem 0.5rem;
        width: 1%;
        word-wrap: break-word;
        border-bottom: 2px solid #ddd; }
        .table-default .table-wrap table th.fit-content,
        .table-default .table-wrap table td.fit-content {
          width: 0.0000001%;
          white-space: nowrap; }
      .table-default .table-wrap table th {
        color: gray;
        border-bottom: 2px solid gray; }
  .table-default.left-aligned .table-wrap {
    text-align: left; }
    .table-default.left-aligned .table-wrap table td,
    .table-default.left-aligned .table-wrap table th {
      padding: 0.8rem 1rem; }
  .table-default.row-selectable table tr th:first-child,
  .table-default.row-selectable table tr td:first-child {
    padding-left: 1.5rem; }
  .table-default.row-selectable table tr.tr-selected {
    border: 3px solid #637DD7; }
    .table-default.row-selectable table tr.tr-selected td:first-child {
      position: relative; }
      .table-default.row-selectable table tr.tr-selected td:first-child::before {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        height: 100%;
        left: 0.5rem;
        width: 0.000001%;
        content: "✓";
        color: green; }

.table-display {
  display: block;
  width: 100%;
  margin-top: 1rem; }
  .table-display .table-wrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    text-align: center; }
    .table-display .table-wrap table {
      width: 99.99%; }
      .table-display .table-wrap table tr:nth-child(even) {
        background: #f6f8f9; }
      .table-display .table-wrap table th,
      .table-display .table-wrap table td {
        margin: 0;
        padding: 0.5rem;
        width: 1%; }
        .table-display .table-wrap table th.fit-content,
        .table-display .table-wrap table td.fit-content {
          width: 0.0000001%; }
        .table-display .table-wrap table th:first-child,
        .table-display .table-wrap table td:first-child {
          font-weight: bold; }
      .table-display .table-wrap table th {
        background: rgba(99, 125, 215, 0.4); }

.table-preprocessing {
  display: block;
  width: 100%;
  margin-top: 1rem;
  position: relative; }
  .table-preprocessing .table-wrap {
    display: block;
    min-height: 10rem;
    max-height: 40rem;
    max-width: 100%;
    overflow: auto;
    text-align: center; }
    .table-preprocessing .table-wrap table {
      width: 99.99%;
      border-spacing: 0; }
      .table-preprocessing .table-wrap table tr:nth-child(even) {
        background: #f6f8f9; }
      .table-preprocessing .table-wrap table tr.tr-selected {
        background: rgba(99, 125, 215, 0.4);
        color: white; }
      .table-preprocessing .table-wrap table th,
      .table-preprocessing .table-wrap table td {
        margin: 0;
        padding: 0.5rem;
        width: 1%; }
        .table-preprocessing .table-wrap table th.fit-content,
        .table-preprocessing .table-wrap table td.fit-content {
          width: 0.0000001%; }
      .table-preprocessing .table-wrap table th {
        background: rgba(99, 125, 215, 0.5);
        border-bottom: 2px solid black; }
      .table-preprocessing .table-wrap table .td-selected {
        background-color: rgba(99, 125, 215, 0.2); }
      .table-preprocessing .table-wrap table .td-added {
        background-color: rgba(97, 179, 142, 0.2); }
      .table-preprocessing .table-wrap table .td-modified {
        background-color: rgba(255, 166, 0, 0.2); }

.table-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.table-filter-multi-selector {
  width: 100%;
  margin: 1rem;
  height: 1.2rem;
  min-width: 7rem; }

.table-filter-rangeslider {
  margin: 1rem 2rem;
  width: 5rem !important;
  color: white;
  min-width: 4rem; }

.table-editable-cell .table-edit-cell-icon {
  display: none; }

.table-editable-cell:hover .table-edit-cell-icon {
  display: inline-block; }

.table-cell-modified {
  color: #ffa600; }

.cell-with-icons {
  width: 100%;
  display: flex;
  align-items: center; }
  .cell-with-icons .cell-content {
    display: flex;
    align-items: center;
    margin-right: auto; }
  .cell-with-icons .cell-icon {
    margin-right: 1rem;
    cursor: pointer; }

.cell-default::before {
  content: "*";
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffa600;
  margin-right: 0.2rem; }

.table-hyperparams {
  display: block;
  width: 100%;
  margin-top: 1rem;
  background-color: white; }
  .table-hyperparams .table-wrap {
    display: block;
    max-width: 99.9%;
    overflow-x: auto; }
    .table-hyperparams .table-wrap table {
      width: 99.9%;
      margin-bottom: 0.1rem;
      border-spacing: 0; }
      .table-hyperparams .table-wrap table th,
      .table-hyperparams .table-wrap table td {
        margin: 0;
        padding: 1rem 1.5rem;
        width: 1%;
        word-wrap: break-word; }
        .table-hyperparams .table-wrap table th .slider,
        .table-hyperparams .table-wrap table th .selector,
        .table-hyperparams .table-wrap table td .slider,
        .table-hyperparams .table-wrap table td .selector {
          margin-left: auto;
          margin-right: auto;
          max-width: 20rem; }
        .table-hyperparams .table-wrap table th .setting-input,
        .table-hyperparams .table-wrap table td .setting-input {
          text-align: center; }
        .table-hyperparams .table-wrap table th.fit-content,
        .table-hyperparams .table-wrap table td.fit-content {
          width: 0.0000001%;
          white-space: nowrap; }
      .table-hyperparams .table-wrap table th {
        text-align: center;
        border-bottom: 2px solid #43425d; }

.case-playground-main {
  margin-top: 1rem;
  display: flex;
  align-items: stretch; }
  .case-playground-main .playground-nav {
    background-color: white;
    padding: 2rem 0;
    width: 22rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    margin: 0 1rem 0 2rem;
    box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2); }
    .case-playground-main .playground-nav .playground-filter .back {
      margin-left: 1rem;
      margin-bottom: 2rem;
      cursor: pointer;
      color: #637DD7; }
    .case-playground-main .playground-nav .nav-item {
      cursor: pointer;
      border-left: 0.3rem solid white;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      width: 90%;
      padding: 0.6rem;
      margin: 0 5%;
      color: #43425d; }
      .case-playground-main .playground-nav .nav-item.selected {
        border-left-color: #637DD7;
        background-color: rgba(99, 125, 215, 0.06); }
      .case-playground-main .playground-nav .nav-item .icon {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem; }

.orange-text {
  color: #ff5e00;
  font-size: 1.5rem; }

.well-dca-panel {
  flex: 1 1;
  flex-direction: column;
  margin-right: 2rem;
  margin-left: 1rem;
  position: relative; }

.wellgroup-panel {
  flex: 1 1;
  flex-direction: column;
  margin-right: 1rem;
  position: relative; }
  .wellgroup-panel .generate-decline {
    margin-left: auto; }
    .wellgroup-panel .generate-decline .generate-decline-panel {
      width: 20rem;
      padding: 1rem; }
  .wellgroup-panel .wellgroup-charts {
    margin-top: 1rem;
    display: grid;
    padding: 0.1rem;
    grid-template-columns: repeat(auto-fit, 30rem);
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    justify-content: space-evenly;
    align-items: center; }
    .wellgroup-panel .wellgroup-charts .group-info {
      display: flex;
      margin: 0 2rem;
      align-self: center; }
      .wellgroup-panel .wellgroup-charts .group-info .group-name {
        color: gray;
        margin-right: 1rem; }
      .wellgroup-panel .wellgroup-charts .group-info .group-value {
        margin-left: auto;
        text-align: right; }
    .wellgroup-panel .wellgroup-charts .wellgroup-chart {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      width: 100%; }
      .wellgroup-panel .wellgroup-charts .wellgroup-chart .wellgroup-decline-plot {
        width: 100%;
        height: 18rem; }

.publish-panel {
  display: flex;
  justify-content: center;
  overflow: auto;
  flex: 1 1; }
  .publish-panel .publish-panel-card {
    padding: 5rem 2rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    background-color: white;
    max-width: 60rem;
    flex: 1 1;
    box-shadow: 0px 0.3rem 0.5rem #00000029; }
    .publish-panel .publish-panel-card.published {
      flex-direction: column; }
      .publish-panel .publish-panel-card.published .published-header {
        font-size: 1.8rem;
        color: #3fcda6;
        margin-bottom: 1.5rem; }
      .publish-panel .publish-panel-card.published .published-subheader {
        font-size: 1.2rem;
        color: #605b5b;
        margin-bottom: 2rem; }
      .publish-panel .publish-panel-card.published .img-cases-header {
        width: 40rem;
        margin-bottom: 2rem; }
      .publish-panel .publish-panel-card.published .notice {
        font-style: italic;
        margin-bottom: 2rem; }
        .publish-panel .publish-panel-card.published .notice .notice-icon {
          color: gray;
          margin-right: 0.5rem; }
    .publish-panel .publish-panel-card .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .publish-panel .publish-panel-card .left .publish-header {
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 2rem; }
      .publish-panel .publish-panel-card .left .publish-subheader {
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 1.5rem; }
      .publish-panel .publish-panel-card .left .prompt {
        margin-bottom: 1.5rem; }
      .publish-panel .publish-panel-card .left .publish-button {
        align-self: center; }
    .publish-panel .publish-panel-card .right {
      width: 30rem; }

.export-data-panel {
  flex: 1 1;
  margin-right: 2rem;
  padding: 4rem 0;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .export-data-panel .export-cards {
    margin-top: 3rem;
    display: flex;
    justify-content: space-evenly;
    width: 100%; }
    .export-data-panel .export-cards .export-card {
      overflow: hidden;
      width: 18rem;
      box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column; }
      .export-data-panel .export-cards .export-card .export-card-header {
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.2rem;
        color: gray;
        background-color: rgba(99, 125, 215, 0.15); }
        .export-data-panel .export-cards .export-card .export-card-header .icon {
          font-size: 4rem;
          margin-bottom: 1rem; }
      .export-data-panel .export-cards .export-card .export-card-content {
        font-size: 0.9rem;
        padding: 1rem;
        color: #555;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1; }
        .export-data-panel .export-cards .export-card .export-card-content .prompt {
          margin-bottom: 1.5rem; }
        .export-data-panel .export-cards .export-card .export-card-content .download-link {
          font-size: 1.1rem;
          margin-top: auto;
          color: #637DD7;
          cursor: pointer; }
          .export-data-panel .export-cards .export-card .export-card-content .download-link .download-icon {
            margin-left: 1rem; }
          .export-data-panel .export-cards .export-card .export-card-content .download-link.disabled {
            color: gray;
            cursor: not-allowed; }

.export-panel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin: 0 calc(50% - 30rem);
  justify-content: center;
  position: relative; }
  .export-panel .export-setting {
    padding: 2rem; }
  .export-panel .export-button {
    align-self: center; }

.report-panel {
  flex: 1 1;
  flex-direction: column;
  margin-right: 1rem; }
  .report-panel .playground-content-header .report-popover-header {
    display: flex;
    font-size: 1.3rem;
    color: gray;
    white-space: nowrap;
    align-items: center;
    margin-bottom: 1rem; }
    .report-panel .playground-content-header .report-popover-header .add-report-image {
      width: 4rem;
      height: 4rem;
      margin-right: 1rem; }
  .report-panel .playground-content-header .prompt {
    text-align: center;
    color: gray;
    font-weight: bold;
    margin-bottom: 0.7rem; }

.report-landing {
  padding: 2rem 2rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  font-weight: 500; }
  .report-landing .report-image {
    width: 16rem;
    margin-top: 2rem; }

.playground-busy-panel {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem; }

.playground-content-header {
  display: flex;
  padding: 1rem 2rem;
  width: 100%; }
  .playground-content-header .left {
    max-width: 50%;
    margin-right: 2rem; }
    .playground-content-header .left .header {
      font-size: 1.3rem; }
    .playground-content-header .left .description {
      font-size: 1rem;
      color: black; }
  .playground-content-header .right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto; }

.field-insight-panel {
  flex: 1 1;
  flex-direction: column;
  margin-right: 1rem;
  position: relative;
  background-color: inherit; }
  .field-insight-panel .field-insight-charts {
    margin-top: 1rem;
    display: grid;
    padding: 0.1rem;
    grid-template-columns: repeat(auto-fit, 32rem);
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    justify-content: space-evenly;
    align-items: center; }
    .field-insight-panel .field-insight-charts .field-insight-chart {
      border-radius: 2px; }
      .field-insight-panel .field-insight-charts .field-insight-chart .header {
        color: gray;
        padding: 1.5rem;
        border-bottom: 2px solid #ddd; }
        .field-insight-panel .field-insight-charts .field-insight-chart .header .header-text {
          font-size: 1.3rem;
          font-weight: bold;
          margin-bottom: 0.5rem; }
      .field-insight-panel .field-insight-charts .field-insight-chart .chart {
        padding: 1rem 0 1rem 1rem; }
    .field-insight-panel .field-insight-charts .field-decline-plot {
      width: 100%;
      height: 20rem; }

.playground-type-curve-view {
  flex: 1 1;
  margin-left: 1rem !important; }
  .playground-type-curve-view .case-typecurve-main {
    margin-left: 2rem;
    margin-right: 2rem; }
    .playground-type-curve-view .case-typecurve-main .case-sub-header-bar {
      margin-top: 0; }

.welcome-to-playground {
  flex: 1 1;
  background-color: white;
  display: flex;
  border-radius: 0.3rem !important; }
  .welcome-to-playground .left {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    margin-right: -3rem; }
    .welcome-to-playground .left .header {
      margin-top: 10rem;
      margin-bottom: 1rem;
      font-size: 2.2rem;
      color: #F35A5F;
      font-weight: bold; }
    .welcome-to-playground .left .prompt {
      color: #657889;
      font-size: 1.5rem;
      margin-bottom: 1rem; }
    .welcome-to-playground .left .prompt-text {
      color: gray; }
  .welcome-to-playground .right {
    width: 74vh;
    height: 72vh; }

.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.case-header {
  background-color: #ffffff;
  min-height: 3.5rem;
  border-bottom: 0.125rem solid #f2f2f2;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 2rem; }
  .case-header .project-name {
    cursor: pointer;
    color: #637DD7;
    text-decoration: underline;
    margin-right: 0.5rem; }
  .case-header .case-name {
    margin-left: 0.5rem; }

.case-header-bar {
  background-color: #ffffff;
  min-height: 4.5rem;
  margin-bottom: 1rem;
  border-bottom: 0.125rem solid #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .case-header-bar .left {
    margin-left: 2rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer; }
    .case-header-bar .left .icon {
      margin-right: 0.5rem; }
  .case-header-bar .case-steps {
    display: flex;
    flex-direction: row;
    z-index: 5;
    align-items: center;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    height: 3rem; }
    .case-header-bar .case-steps .case-step-item {
      display: flex;
      flex-direction: column;
      font-size: 0.8rem;
      align-items: center;
      justify-content: center;
      color: gray;
      margin: 0.5rem;
      white-space: nowrap; }
      .case-header-bar .case-steps .case-step-item.done {
        color: #637DD7; }
      .case-header-bar .case-steps .case-step-item .case-step-text {
        text-decoration: underline;
        cursor: pointer; }
      .case-header-bar .case-steps .case-step-item .case-step-icon {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center; }
        .case-header-bar .case-steps .case-step-item .case-step-icon .done-icon {
          width: 1.2rem;
          height: 1.2rem; }
        .case-header-bar .case-steps .case-step-item .case-step-icon .current-icon {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          background-color: #637DD7;
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-animation: pulse 3s infinite;
                  animation: pulse 3s infinite; }
    .case-header-bar .case-steps .line {
      -webkit-transform: translateY(1rem);
              transform: translateY(1rem);
      width: 2rem;
      height: 1px;
      border: 1px solid lightgray; }
      .case-header-bar .case-steps .line.done {
        border-color: #637DD7; }
  .case-header-bar .right {
    margin-right: 2rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: right;
    cursor: pointer; }
    .case-header-bar .right .icon {
      margin-left: 0.5rem; }

.case-sub-header-bar {
  background-color: inherit;
  width: 100%;
  height: 4rem;
  position: relative; }
  .case-sub-header-bar .back {
    color: #4d4f5c;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .case-sub-header-bar .header {
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center; }
  .case-sub-header-bar .banner {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 5px solid #61B38E;
    right: 1rem;
    min-width: 20%;
    max-width: 60%;
    min-height: 100%;
    top: 1%;
    -webkit-animation: fadein 0.3s ease-in;
            animation: fadein 0.3s ease-in; }
    .case-sub-header-bar .banner.info {
      border-top-color: #637DD7; }
    .case-sub-header-bar .banner.warning {
      border-top-color: #ffa600; }
    .case-sub-header-bar .banner.error {
      border-top-color: #DE7A83; }

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0.4rem rgba(99, 125, 215, 0.2); }
  50% {
    box-shadow: 0 0 0 0.4rem rgba(99, 125, 215, 0.4); }
  100% {
    box-shadow: 0 0 0 0.4rem rgba(99, 125, 215, 0.2); } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0.4rem rgba(99, 125, 215, 0.2); }
  50% {
    box-shadow: 0 0 0 0.4rem rgba(99, 125, 215, 0.4); }
  100% {
    box-shadow: 0 0 0 0.4rem rgba(99, 125, 215, 0.2); } }

.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.decline-executing-panel {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  background: white;
  display: flex;
  align-items: stretch;
  margin-left: 2rem;
  margin-right: 2rem;
  min-height: 20rem; }
  .decline-executing-panel .left {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid gray; }
    .decline-executing-panel .left .executing-header {
      color: #637DD7;
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 1rem; }
    .decline-executing-panel .left .dca-progress {
      width: 90%;
      margin-bottom: 0.5rem; }
    .decline-executing-panel .left .executed-header {
      color: #61B38E;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 2rem; }
  .decline-executing-panel .right {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 2rem; }
    .decline-executing-panel .right .header {
      align-self: center;
      color: gray;
      font-size: 1.3rem;
      margin-bottom: 1.5rem; }
    .decline-executing-panel .right .dca-tip {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
      .decline-executing-panel .right .dca-tip .dca-tip-msg {
        margin-left: 1rem; }

.table-well {
  margin: 2rem 5%;
  width: 90%; }
  .table-well th, .table-well td {
    font-size: 0.9rem;
    white-space: nowrap; }

.well-qc-panel {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 1rem;
  margin: 0 5%;
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible; }
  .well-qc-panel .qc-header {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .well-qc-panel .qc-header .qc-header-prompt {
      color: #637DD7; }
    .well-qc-panel .qc-header .qc-header-error {
      color: #DE7A83;
      font-size: 1rem;
      font-style: italic; }

.well-qc-chart {
  width: 90%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible; }
  .well-qc-chart .plot-well-dca-qc {
    height: 35rem;
    width: 100%; }
  .well-qc-chart .plot-typecurve-qc {
    height: 25rem;
    width: 100%; }
  .well-qc-chart .event-dot {
    background-color: rgba(97, 179, 142, 0.5);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    z-index: 10;
    position: absolute !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .well-qc-chart .event-dot .popover-content {
      padding: 0 1rem;
      min-width: 15rem;
      align-items: center; }
      .well-qc-chart .event-dot .popover-content .delete-event-icon {
        color: #DE7A83;
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 2rem;
        margin-top: 0.5rem;
        cursor: pointer; }
      .well-qc-chart .event-dot .popover-content .qc-prompt {
        margin-right: 1.8rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem; }
      .well-qc-chart .event-dot .popover-content .qc-dca-parameters-bar {
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly; }
        .well-qc-chart .event-dot .popover-content .qc-dca-parameters-bar .qc-dca-parameter {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .well-qc-chart .event-dot .popover-content .qc-dca-parameters-bar .qc-dca-parameter .setting-input {
            width: 5rem;
            margin: 0 0.5rem;
            text-align: center; }
            .well-qc-chart .event-dot .popover-content .qc-dca-parameters-bar .qc-dca-parameter .setting-input::-webkit-inner-spin-button {
              opacity: 1; }
            .well-qc-chart .event-dot .popover-content .qc-dca-parameters-bar .qc-dca-parameter .setting-input::-webkit-outer-spin-button {
              opacity: 1; }

.pick-mode-panel {
  background-color: white;
  margin: 5rem calc(min(20%, 20rem));
  margin-bottom: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #C8C8C8;
  box-shadow: 2px 2px 6px #0000000F; }
  .pick-mode-panel .header {
    font-size: 1.6rem;
    color: #555;
    margin-bottom: 1rem;
    font-weight: bold; }
  .pick-mode-panel .prompt .mode-name {
    color: #637DD7;
    font-weight: bold;
    margin-right: 0.1rem; }
  .pick-mode-panel .mode-cards {
    display: flex;
    margin-top: 2rem; }
    .pick-mode-panel .mode-cards .mode-card {
      background-color: white;
      border: 1px solid #E5E5E5;
      border-top: 3px solid #637DD7;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
      font-weight: bold;
      width: 15rem;
      height: 15rem;
      color: #637DD7;
      border-radius: 0.2rem !important; }
      .pick-mode-panel .mode-cards .mode-card:hover {
        box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
        -webkit-transform: translateY(-0.2rem);
                transform: translateY(-0.2rem);
        background-color: #e9f4ff;
        cursor: pointer; }
      .pick-mode-panel .mode-cards .mode-card .mode-img {
        height: 8rem;
        width: 9rem;
        margin-bottom: 1rem; }

.decline-main {
  background: inherit;
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .decline-main .decline-panel {
    display: flex;
    align-self: flex-start;
    position: relative;
    flex-direction: column;
    border: solid 0.125rem #e1e1e1;
    border-radius: 0.9375rem;
    background: white;
    width: 22.5rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    transition: width ease-out 0.1s; }
    .decline-main .decline-panel.panel-collapsed {
      width: 2.5rem;
      align-items: center;
      padding: 1rem 0;
      transition: width ease-out 0.2s; }
      .decline-main .decline-panel.panel-collapsed .icon-collapsed {
        width: 1.5rem;
        height: 1.5rem;
        margin: 1.5rem 0.5rem; }
    .decline-main .decline-panel .decline-panel-header {
      height: 90px;
      border-bottom: solid 0.125rem #e1e1e1;
      border-radius: 0.9375rem 0.9375rem 0 0;
      overflow: hidden; }
    .decline-main .decline-panel .decline-panel-content {
      position: relative;
      min-height: 37rem;
      max-height: 70rem;
      overflow-y: auto;
      flex: 1, 1; }
      .decline-main .decline-panel .decline-panel-content .dcarun-setting-panel {
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center; }
        .decline-main .decline-panel .decline-panel-content .dcarun-setting-panel .setting-prompt {
          font-size: 0.9rem;
          color: gray;
          margin-bottom: 1rem; }
          .decline-main .decline-panel .decline-panel-content .dcarun-setting-panel .setting-prompt .number-selected-wells {
            color: #61B38E;
            font-size: 1.3rem;
            font-weight: bold; }
    .decline-main .decline-panel .decline-panel-toggle {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 10;
      -webkit-transform: translate(50%, -50%);
              transform: translate(50%, -50%); }
  .decline-main .decline-main-content {
    flex: 1 1;
    flex-direction: column;
    background: inherit;
    overflow: hidden;
    margin-left: 0.5rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
    position: relative; }
    .decline-main .decline-main-content .customize-setting-panel {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #CBCBCB;
      border-radius: 2rem;
      margin-top: 6rem;
      background-color: white;
      padding: 5rem 2rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center; }
      .decline-main .decline-main-content .customize-setting-panel .left {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
        .decline-main .decline-main-content .customize-setting-panel .left .prompt {
          font-size: 1rem;
          color: gray;
          text-align: center; }
        .decline-main .decline-main-content .customize-setting-panel .left .header {
          font-size: 2rem;
          color: #637DD7;
          margin-bottom: 2rem; }
    .decline-main .decline-main-content .status-bar {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem; }
      .decline-main .decline-main-content .status-bar .status-wells {
        margin: 0 1rem;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer; }
        .decline-main .decline-main-content .status-bar .status-wells.selected {
          border: 3px solid;
          cursor: default; }
        .decline-main .decline-main-content .status-bar .status-wells.primary {
          border-color: #637DD7; }
        .decline-main .decline-main-content .status-bar .status-wells.error {
          border-color: #DE7A83; }
        .decline-main .decline-main-content .status-bar .status-wells.success {
          border-color: #61B38E; }
        .decline-main .decline-main-content .status-bar .status-wells .upper {
          width: 100%;
          background-color: #F5F5FA;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 3rem; }
          .decline-main .decline-main-content .status-bar .status-wells .upper .icon {
            font-size: 1.5rem; }
        .decline-main .decline-main-content .status-bar .status-wells .lower {
          text-align: center;
          padding: 0.5rem 1rem; }
          .decline-main .decline-main-content .status-bar .status-wells .lower .number {
            font-size: 1.3rem;
            font-weight: bold; }
  .decline-main .decline-log {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    color: black;
    opacity: 0.6; }
    .decline-main .decline-log .logHeader {
      font: 1.0rem/1.5rem;
      font-weight: bold;
      letter-spacing: 0.01rem;
      text-align: left;
      margin-bottom: 0.65rem; }
    .decline-main .decline-log .date {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      text-align: center; }
    .decline-main .decline-log .log-detail {
      padding: 0.95rem;
      border-radius: 0.2rem; }
      .decline-main .decline-log .log-detail .time-row {
        font: 0.85rem/1.2rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.1rem; }
        .decline-main .decline-log .log-detail .time-row .timestamp {
          float: left; }
        .decline-main .decline-log .log-detail .time-row .timespan {
          float: right; }
      .decline-main .decline-log .log-detail .description {
        font: 0.85rem/1.2rem;
        text-align: left; }
        .decline-main .decline-log .log-detail .description .dot {
          height: 0.3rem;
          width: 0.3rem;
          background-color: #61B38E;
          border-radius: 50%;
          display: inline-block;
          margin-left: -0.75rem;
          margin-right: 0.5rem;
          vertical-align: middle; }
      .decline-main .decline-log .log-detail .toggle-setting-table {
        text-decoration: underline;
        color: #637DD7;
        cursor: pointer; }
    .decline-main .decline-log :focus {
      outline: 0;
      outline: none; }
    .decline-main .decline-log .highlight {
      background: #3fcda6 0% 0% no-repeat padding-box; }
    .decline-main .decline-log .log-restore {
      text-align: center;
      text-decoration: underline;
      font: 1.0rem/1.5rem;
      letter-spacing: 0;
      color: #0076c0;
      margin-top: 0.5rem;
      opacity: 1 !important; }
      .decline-main .decline-log .log-restore .icon {
        color: black;
        margin-right: 0.25rem;
        font: 1.2rem/1.5rem; }
  .decline-main .decline-log-done {
    opacity: 1; }
  .decline-main .redo-undo-bar {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .decline-main .redo-undo-bar .left {
      margin-left: 1.5rem;
      font-weight: bold;
      cursor: pointer; }
      .decline-main .redo-undo-bar .left .icon {
        margin-right: 0.5rem; }
    .decline-main .redo-undo-bar .redo-undo-icon {
      margin-right: 1.5rem;
      display: flex;
      cursor: not-allowed; }
      .decline-main .redo-undo-bar .redo-undo-icon .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        color: #ddd;
        border: 0.125rem solid #ddd;
        border-radius: 1rem;
        margin-left: 1rem; }
        .decline-main .redo-undo-bar .redo-undo-icon .circle.active {
          cursor: pointer;
          color: black;
          border: 0.5px solid black; }
  .decline-main .decline-tab-selector {
    display: flex;
    width: 100%;
    height: 100%; }
    .decline-main .decline-tab-selector .tab-option {
      cursor: pointer;
      outline: 0 !important;
      box-shadow: none !important;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .decline-main .decline-tab-selector .tab-option .icon {
        width: 22px !important;
        height: 22px;
        position: relative;
        color: #4b4d53; }
      .decline-main .decline-tab-selector .tab-option .text {
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
        font-size: 1rem;
        font-weight: normal; }
      .decline-main .decline-tab-selector .tab-option .selected {
        color: white;
        font-weight: bold; }
      .decline-main .decline-tab-selector .tab-option.selected {
        background: #43425d; }
      .decline-main .decline-tab-selector .tab-option.disabled {
        background: gray;
        cursor: not-allowed; }
  .decline-main .decline-autopilot {
    border: 0.125rem solid #f4f4f4;
    border-radius: 0.125rem;
    padding: 1rem;
    font: 1.0rem/1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #0B0D11; }
    .decline-main .decline-autopilot .left {
      margin-left: 0.25rem; }
      .decline-main .decline-autopilot .left .icon {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.25rem; }
    .decline-main .decline-autopilot .run {
      color: #3FCDA6;
      text-decoration: underline;
      margin-right: 0.15rem; }

.filter-header {
  display: flex;
  align-items: center;
  margin: 1rem; }
  .filter-header .clear-filter {
    font-size: 0.8rem;
    border-radius: 1rem;
    border: 1px solid black;
    padding: 0.3rem 1rem;
    cursor: pointer; }
  .filter-header .search-well {
    flex: 1 1;
    margin-right: 1rem; }

.decline-accordion {
  border: 1px solid #f4f4f4;
  border-radius: 0.125rem;
  direction: rtl;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .decline-accordion .item {
    border-top: 0.125rem solid #f4f4f4;
    background-color: white;
    direction: ltr; }
    .decline-accordion .item .header {
      background-color: white;
      display: flex;
      height: 50px;
      width: 100%; }
      .decline-accordion .item .header:hover {
        cursor: pointer; }
      .decline-accordion .item .header .button {
        display: flex;
        width: 100%; }
        .decline-accordion .item .header .button .inner-accordion-header {
          width: 100%;
          height: 100%;
          align-self: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-items: center; }
          .decline-accordion .item .header .button .inner-accordion-header .icon {
            margin-left: 1rem;
            margin-right: 1rem; }
          .decline-accordion .item .header .button .inner-accordion-header .name {
            font-size: 1rem;
            font-weight: normal;
            margin-right: auto; }
          .decline-accordion .item .header .button .inner-accordion-header .icon-right {
            margin-right: 1rem; }
        .decline-accordion .item .header .button .flag {
          color: #DE7A83 !important; }
        .decline-accordion .item .header .button .text-accordion-selected {
          color: green; }
        .decline-accordion .item .header .button .header-accordion-selected {
          background: #ddd; }
    .decline-accordion .item .panel {
      padding: 1.25rem;
      -webkit-animation: fadein 0.35s ease-in;
              animation: fadein 0.35s ease-in; }

.dca-charts-panel {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 30rem; }
  .dca-charts-panel .dca-charts-header {
    font-size: 0.9rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 1rem; }
    .dca-charts-panel .dca-charts-header .well-count {
      color: gray;
      font-size: 1.1rem;
      font-weight: bold;
      margin-right: 1rem; }
      .dca-charts-panel .dca-charts-header .well-count .filtered {
        color: #637DD7; }
    .dca-charts-panel .dca-charts-header .curve-img {
      width: 2rem; }
  .dca-charts-panel .sort {
    display: flex;
    align-self: flex-start;
    align-items: center; }
    .dca-charts-panel .sort .sort-icon {
      font-size: 1.3rem;
      margin-left: 0.5rem;
      cursor: pointer; }
  .dca-charts-panel .chart-select-option {
    margin-left: 0.5rem;
    width: 10rem; }

.well-by-well-charts {
  margin-top: 1rem;
  display: grid;
  padding: 0.1rem;
  grid-template-columns: repeat(auto-fit, 28rem);
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  justify-content: space-evenly;
  align-items: center; }
  .well-by-well-charts .well-chart {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
    height: 18rem; }
    .well-by-well-charts .well-chart.well-to-qc {
      border: 0.125rem solid #637DD7; }
    .well-by-well-charts .well-chart .well-chart-header {
      padding: 0.5rem 1rem 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      .well-by-well-charts .well-chart .well-chart-header .well-name {
        font-size: 1rem;
        font-weight: bold; }
      .well-by-well-charts .well-chart .well-chart-header .run-info {
        margin-left: 0.5rem;
        font-size: 0.8rem;
        font-style: italic;
        cursor: pointer;
        color: #637DD7;
        text-decoration: underline; }
        .well-by-well-charts .well-chart .well-chart-header .run-info.selected {
          color: #3fcda6; }
      .well-by-well-charts .well-chart .well-chart-header .view-details-icon {
        margin-left: auto;
        font-size: 1rem;
        color: #637DD7;
        cursor: pointer; }
      .well-by-well-charts .well-chart .well-chart-header .status-icon {
        margin-left: 0.8rem;
        cursor: pointer; }
    .well-by-well-charts .well-chart .plot-well-dca {
      width: 100%;
      height: 100%; }
    .well-by-well-charts .well-chart .no-production {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      color: gray; }

.well-info-static {
  position: relative;
  width: 28rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .well-info-static .name {
    padding-top: 0.5rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold; }
  .well-info-static .well-chart-static {
    width: 96%;
    height: 100%; }
    .well-info-static .well-chart-static .plot-well-dca-static {
      width: 100%;
      height: 100%; }
  .well-info-static .table-well-static {
    margin-top: 0.5rem;
    width: 98%; }
    .well-info-static .table-well-static th, .well-info-static .table-well-static td {
      font-size: 0.7rem;
      white-space: nowrap; }

.toggle-show-filter {
  margin-right: 1rem;
  font-size: 0.8rem;
  border-radius: 1rem;
  border: 1px solid black;
  padding: 0.3rem 1rem;
  cursor: pointer; }

.typecurve-group-panel {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  background: white;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: stretch;
  margin: 1rem 18%;
  position: relative; }
  .typecurve-group-panel.regroup-panel {
    margin: 0; }
    .typecurve-group-panel.regroup-panel .group-info .info-icon {
      margin-left: auto;
      margin-right: 0.1rem; }
    .typecurve-group-panel.regroup-panel .regroup-warning-icon {
      color: #ffa600;
      margin-right: 0.5rem; }
  .typecurve-group-panel .group-prompt {
    display: flex;
    align-items: center;
    color: gray;
    margin-bottom: 1.5rem; }
  .typecurve-group-panel .group-checkbox {
    display: flex; }
  .typecurve-group-panel .group-info {
    display: flex;
    align-items: center;
    flex: 1 1; }
    .typecurve-group-panel .group-info .info-icon {
      margin-left: auto;
      margin-right: 1rem;
      color: gray; }
  .typecurve-group-panel .action-bar {
    align-self: center;
    margin-top: 1rem;
    display: flex;
    align-items: center; }
    .typecurve-group-panel .action-bar .btn-remove-group {
      margin-right: 1rem;
      color: #637DD7;
      cursor: pointer; }

.within-group-panel {
  display: flex;
  align-items: flex-start; }
  .within-group-panel .typecurve-qc-chart {
    flex: 1 1;
    margin-right: 1rem;
    padding: 0.5rem;
    height: 28rem; }
    .within-group-panel .typecurve-qc-chart .well-qc-chart {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .within-group-panel .typecurve-qc-chart .plot-typecurve-qc {
      width: 100%;
      height: 100%; }
  .within-group-panel .clustering {
    width: 30rem;
    display: flex;
    flex-direction: column; }
    .within-group-panel .clustering .clustering-settings {
      display: flex;
      flex-direction: column;
      padding: 1rem 2rem; }
      .within-group-panel .clustering .clustering-settings .header {
        font-weight: bold;
        margin-bottom: 1rem; }
      .within-group-panel .clustering .clustering-settings .action-bar {
        margin-top: 1rem;
        align-self: center;
        display: flex;
        align-items: center; }
        .within-group-panel .clustering .clustering-settings .action-bar .btn-remove-cluster {
          margin-right: 1rem;
          color: #637DD7;
          cursor: pointer; }
    .within-group-panel .clustering .clusters {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      padding: 1rem; }
      .within-group-panel .clustering .clusters .header {
        display: flex; }
        .within-group-panel .clustering .clusters .header .number-wells {
          margin-left: 1rem;
          color: gray; }
        .within-group-panel .clustering .clusters .header .cluster-view-all {
          margin-left: auto;
          color: #637DD7;
          cursor: pointer; }
      .within-group-panel .clustering .clusters .view-cluster {
        color: #637DD7;
        cursor: pointer; }

.case-typecurve-main {
  background: inherit;
  margin: 0.5rem 5%;
  display: flex;
  flex-direction: column;
  position: relative; }
  .case-typecurve-main .map-info {
    display: flex;
    margin-bottom: 1.5rem; }
    .case-typecurve-main .map-info .google-map-container {
      flex: 1 1;
      height: 25rem; }
  .case-typecurve-main .well-info-static {
    margin-left: 1rem;
    width: 30rem; }
  .case-typecurve-main .well-marker {
    position: absolute;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .case-typecurve-main .well-marker .marker {
      cursor: pointer; }
  .case-typecurve-main .well-marker-tooltip {
    min-width: 10rem;
    text-align: center; }
  .case-typecurve-main .group-header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center; }
    .case-typecurve-main .group-header .group-chip {
      display: flex;
      align-items: center;
      border-radius: 1000px;
      padding: 0.1rem 0.5rem;
      font-weight: bold;
      color: white;
      margin-right: 1rem; }
    .case-typecurve-main .group-header .group-header-prompt {
      color: gray; }
    .case-typecurve-main .group-header .regroup-wells {
      margin-left: auto;
      margin-right: 2rem; }
      .case-typecurve-main .group-header .regroup-wells .menu-icon {
        margin-right: 0.5rem;
        color: #637DD7;
        cursor: pointer; }
      .case-typecurve-main .group-header .regroup-wells .regroup-panel {
        width: 20rem;
        padding: 1rem; }
    .case-typecurve-main .group-header .view-map-switch {
      margin-right: 0.3rem; }
  .case-typecurve-main .group-cards {
    margin-top: 1rem;
    display: grid;
    padding: 0.1rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    justify-content: space-between; }
    .case-typecurve-main .group-cards .group-card {
      display: flex;
      flex-direction: column;
      max-width: 30rem;
      padding-bottom: 1rem;
      max-height: 25rem;
      overflow: auto; }
      .case-typecurve-main .group-cards .group-card:hover {
        box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
        -webkit-transform: translateY(-0.2rem);
                transform: translateY(-0.2rem);
        background-color: #e9f4ff;
        cursor: pointer; }
      .case-typecurve-main .group-cards .group-card .group-card-header {
        padding: 0.5rem 1rem;
        border-bottom: 1px dashed gray;
        font-size: 1.1rem;
        font-weight: bold;
        display: flex;
        align-items: center; }
        .case-typecurve-main .group-cards .group-card .group-card-header .group-highlight {
          color: black;
          margin-left: auto;
          font-size: 0.8rem;
          text-decoration: underline;
          cursor: pointer; }
          .case-typecurve-main .group-cards .group-card .group-card-header .group-highlight .map-marker {
            margin-right: 0.5rem;
            display: inline; }
      .case-typecurve-main .group-cards .group-card .group-card-subheader {
        margin-top: 1rem;
        padding: 0.1rem 2rem;
        display: flex;
        align-items: center; }
      .case-typecurve-main .group-cards .group-card .group-info {
        display: flex;
        margin: 0.5rem 2rem; }
        .case-typecurve-main .group-cards .group-card .group-info .group-name {
          color: gray;
          margin-right: 1rem; }
        .case-typecurve-main .group-cards .group-card .group-info .group-value {
          margin-left: auto;
          text-align: right; }

.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.files-panel {
  margin-top: 2rem;
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .files-panel .header {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center; }
  .files-panel .files-table {
    width: 95%; }
  .files-panel .icon {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;
    color: gray; }
  .files-panel .download-icon {
    cursor: pointer;
    font-size: 1.2rem;
    color: gray; }
  .files-panel .file-upload-progressbar {
    margin-right: 1rem;
    min-width: 4rem;
    max-width: 20rem;
    flex: 1 1; }

.case-initialize-main {
  margin: 1rem calc(50% - 40rem);
  background: inherit;
  display: flex;
  flex-direction: column;
  position: relative; }
  .case-initialize-main .header {
    display: flex;
    font-size: 1.5rem;
    align-self: center;
    font-weight: bold;
    margin-bottom: 1rem; }
  .case-initialize-main .subheader {
    display: flex;
    font-size: 1.2rem;
    color: gray;
    align-self: center;
    margin-bottom: 1rem; }
  .case-initialize-main .file-details {
    margin-top: 2rem;
    box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: white; }
    .case-initialize-main .file-details .file-details-header {
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      padding: 0 2rem 1rem 2rem;
      justify-content: space-between;
      border-bottom: 0.25rem solid gray; }
      .case-initialize-main .file-details .file-details-header .download-icon {
        cursor: pointer; }
  .case-initialize-main .file-selection-panel {
    overflow-x: auto; }
  .case-initialize-main .select-production-column-header {
    margin: 2rem 0;
    font-size: 1.3rem;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .case-initialize-main .select-production-column-inputs {
    width: 100%;
    display: flex;
    justify-content: center; }
    .case-initialize-main .select-production-column-inputs .select-production-column {
      width: 20rem;
      max-width: 40%;
      margin-right: 2rem; }
  .case-initialize-main .initialize-button {
    margin-top: 1rem;
    display: flex;
    justify-content: center; }

.search-file-header-bar {
  width: 100%;
  height: 4rem;
  position: relative; }
  .search-file-header-bar .search-box {
    position: absolute;
    width: 30rem;
    max-width: 35%;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .search-file-header-bar .header-text {
    font-size: 1.3rem;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.no-file {
  font-size: 1rem; }

.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media screen and (min-width: 768px) {
  .project-page-content {
    padding: 2rem 3rem; } }

.project-page-content .project-title {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center; }
  .project-page-content .project-title .project-collabs {
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-self: right !important;
    align-items: center; }
    .project-page-content .project-title .project-collabs .avatar {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      border-radius: 1.25rem;
      background: #4b4d53;
      text-align: center;
      margin: 0 .1rem; }
      .project-page-content .project-title .project-collabs .avatar__text {
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        pointer-events: none;
        color: #ffffff; }
    .project-page-content .project-title .project-collabs .avatar-creator {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      border-radius: 1.25rem;
      background: #0076c0;
      text-align: center;
      margin: 0 .1rem; }
      .project-page-content .project-title .project-collabs .avatar-creator__text {
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        pointer-events: none;
        color: #ffffff; }
    .project-page-content .project-title .project-collabs .avatar-icon {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      border-radius: 50%;
      background: #4b4d53;
      text-align: center;
      margin-left: .2rem; }
      .project-page-content .project-title .project-collabs .avatar-icon:hover {
        background-color: #0076c0; }
      .project-page-content .project-title .project-collabs .avatar-icon__text {
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 1.5rem;
        pointer-events: none;
        color: #ffffff; }

.project-page-content .no-cases-display {
  padding: 5rem 10rem 5rem 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .project-page-content .no-cases-display .no-cases-title {
    color: #0076c0; }

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto; }
  @media screen and (max-width: 768px) {
    .modal-dialog {
      min-height: calc(100vh - 1.25rem); } }
  @media screen and (min-width: 768px) {
    .modal-dialog {
      max-width: 40rem !important; } }
  .modal-dialog .modal-content.project-share-modal {
    width: 90rem;
    background-color: yellow; }
  .modal-dialog .new-case-footer {
    text-align: right; }
    .modal-dialog .new-case-footer__button {
      background: #3fcda6;
      width: 12rem;
      height: 2.5rem;
      border-radius: 2rem;
      color: #ffffff;
      margin-bottom: 1rem;
      margin-right: 1rem;
      line-height: 1rem;
      font-weight: 500; }
      .modal-dialog .new-case-footer__button:hover {
        background-color: #0b7a6a; }
  .modal-dialog .project-collab-modal {
    display: grid !important;
    grid-template-columns: 35% 65%;
    height: 26rem;
    font-size: 1.2rem;
    font-weight: 400; }
    .modal-dialog .project-collab-modal .project-collab-content {
      display: flex; }
      .modal-dialog .project-collab-modal .project-collab-content__left {
        padding: 1rem 0 0 1rem;
        background-color: #f7f7f7; }
        .modal-dialog .project-collab-modal .project-collab-content__left .project-owner {
          padding-top: .4rem;
          font-size: 1rem;
          padding-top: .4rem;
          padding-left: 1rem !important;
          line-height: 1.5rem;
          padding-bottom: 1rem; }
        .modal-dialog .project-collab-modal .project-collab-content__left .collabs {
          display: grid;
          grid-template-columns: 100%;
          list-style-type: none !important;
          padding-left: 1rem !important;
          font-size: 1rem;
          padding-top: .4rem;
          line-height: 1.5rem; }
          .modal-dialog .project-collab-modal .project-collab-content__left .collabs .collab-remove-icon {
            padding-left: .8rem; }
        .modal-dialog .project-collab-modal .project-collab-content__left .share-qri-warning {
          text-align: center;
          font-size: .8rem;
          padding-bottom: 1rem;
          padding-right: 1rem; }
      .modal-dialog .project-collab-modal .project-collab-content__right {
        padding: 1rem 1rem 0 1rem; }
        .modal-dialog .project-collab-modal .project-collab-content__right .collab-button-parent {
          display: block;
          text-align: right; }
          .modal-dialog .project-collab-modal .project-collab-content__right .collab-button-parent .collab-add-button {
            width: 15rem;
            font-weight: 500;
            font-size: 1rem;
            height: 3rem;
            border-radius: 2rem;
            background-color: #3fcda6;
            color: #f7f7f7;
            margin-top: 2rem;
            line-height: 1rem; }
            .modal-dialog .project-collab-modal .project-collab-content__right .collab-button-parent .collab-add-button:hover {
              background-color: #0b7a6a; }

.cases-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  transition: 0.65s !important; }
  .cases-search .add-case {
    background: #3fcda6;
    border-radius: 2rem;
    color: #ffffff;
    width: 15rem;
    height: 3.5rem;
    font-weight: 500; }
    .cases-search .add-case:active {
      outline: none !important; }
    .cases-search .add-case:focus {
      outline: none !important; }
    .cases-search .add-case:hover {
      background-color: #0b7a6a; }

.input-with-icon {
  border: 1px solid #d6d5d5 !important; }

.project-tab-selector {
  display: flex;
  width: 40rem;
  height: 3rem;
  line-height: 50px;
  text-align: center;
  align-items: center; }
  .project-tab-selector .tab-option {
    cursor: pointer;
    outline: 0 !important;
    box-shadow: none !important;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-left: 1px solid; }
    .project-tab-selector .tab-option:first-child {
      border-left: none !important; }
    .project-tab-selector .tab-option:nth-child(2) {
      width: 42rem; }
    .project-tab-selector .tab-option .text {
      padding-left: .4rem; }
    .project-tab-selector .tab-option.selected {
      background-color: #4b4d53;
      color: #ffffff;
      font-weight: bold; }

.case-table {
  position: relative;
  width: 100%;
  transition: 0.65s;
  padding: 0.125rem 0.125rem 0px 0.125rem; }
  .case-table .case-item {
    display: grid;
    grid-template-columns: minmax(16rem, auto) minmax(18rem, 18%) 1fr minmax(6rem, 6%) minmax(8rem, 8%) 15rem;
    margin-bottom: 0.9375rem;
    margin-top: 0.9375rem;
    min-height: 8rem;
    background: #ffffff;
    box-shadow: 1px 0px 0.125rem 1px rgba(189, 189, 189, 0.75);
    color: #4b4d53;
    cursor: pointer;
    outline: 0 !important;
    align-items: center;
    position: relative;
    font-size: 0.9rem; }
    @media screen and (max-width: 1000px) {
      .case-table .case-item {
        font-size: 0.7rem; } }
    .case-table .case-item .case-item-one {
      display: flex;
      min-width: 0; }
      .case-table .case-item .case-item-one__name-wrapper {
        width: 100%;
        padding-left: 1.25rem;
        padding-right: 1.25rem; }
        .case-table .case-item .case-item-one__name-wrapper .flex-child {
          max-width: 100%; }
      .case-table .case-item .case-item-one .name {
        color: #525252;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.4rem; }
      .case-table .case-item .case-item-one .owner {
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .case-table .case-item .case-item-two {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media screen and (max-width: 1024px) {
        .case-table .case-item .case-item-two {
          display: none; } }
      .case-table .case-item .case-item-two .created {
        color: #ea813d;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .case-table .case-item .case-item-two .description {
        padding-top: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .case-table .case-item .case-item-file {
      display: flex;
      flex-direction: column; }
      .case-table .case-item .case-item-file .file-item {
        padding-right: 1rem;
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: 100%; }
        .case-table .case-item .case-item-file .file-item .file-type {
          font-weight: bold;
          width: 8rem; }
        .case-table .case-item .case-item-file .file-item .file-name {
          margin-left: 0.05rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #637DD7; }
          .case-table .case-item .case-item-file .file-item .file-name:hover {
            text-decoration: underline; }
    .case-table .case-item .case-item-summary {
      display: flex;
      justify-content: center;
      width: 100%; }
      .case-table .case-item .case-item-summary .review-experiment {
        background: rgba(0, 119, 255, 0.2);
        border-radius: 100px;
        width: 7rem;
        height: 2.5rem;
        color: #0077ff;
        font-weight: bold; }
        .case-table .case-item .case-item-summary .review-experiment:hover {
          background: #0077ff;
          color: whitesmoke; }
    .case-table .case-item .case-item-status .case-status {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .case-table .case-item .case-item-status .case-status p {
        margin-top: 0.5rem !important;
        margin-bottom: 0;
        white-space: nowrap; }
      .case-table .case-item .case-item-status .case-status .progress {
        margin: 0.7rem 0 0.7rem 0;
        height: 0.8rem;
        width: 3.75rem;
        background-color: #ddf7f4;
        display: flex;
        align-items: center;
        border-radius: 0.4rem; }
        @media screen and (max-width: 1280px) {
          .case-table .case-item .case-item-status .case-status .progress {
            height: 0.8rem;
            width: 2.75rem;
            margin: 0.4rem 0 0.2rem 0; } }
        .case-table .case-item .case-item-status .case-status .progress .bar {
          background-color: #1aceb3;
          height: 0.8rem; }
      .case-table .case-item .case-item-status .case-status .not-finalized-action-button {
        height: 2.2rem;
        width: 2.2rem;
        background-color: #ffcb4d;
        display: flex;
        align-items: center;
        border-radius: 50%; }
        .case-table .case-item .case-item-status .case-status .not-finalized-action-button__text {
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 1.5rem;
          color: #ffffff; }
        @media screen and (max-width: 1024px) {
          .case-table .case-item .case-item-status .case-status .not-finalized-action-button {
            height: 1rem;
            width: 1rem; } }
      .case-table .case-item .case-item-status .case-status .finalized-action-button {
        height: 2.2rem;
        width: 2.2rem;
        background-color: #3fcda6;
        display: flex;
        align-items: center;
        border-radius: 50%; }
        @media screen and (max-width: 1024px) {
          .case-table .case-item .case-item-status .case-status .finalized-action-button {
            height: 1rem;
            width: 1rem; } }
        .case-table .case-item .case-item-status .case-status .finalized-action-button__text {
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 1.5rem;
          color: #ffffff; }
          @media screen and (max-width: 1024px) {
            .case-table .case-item .case-item-status .case-status .finalized-action-button__text {
              font-size: .8rem; } }
    .case-table .case-item .case-item-buttons {
      border-left: 1px solid #e6e6e6;
      height: 100%;
      display: flex;
      flex-direction: row;
      text-align: right;
      justify-self: right !important;
      align-items: center;
      padding: 0 1rem;
      flex-shrink: 0; }
      .case-table .case-item .case-item-buttons .action-buttons {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        background-color: #646464;
        margin: 0 .4rem;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media screen and (max-width: 1024px) {
          .case-table .case-item .case-item-buttons .action-buttons {
            height: 1rem;
            width: 1rem; } }
        .case-table .case-item .case-item-buttons .action-buttons__text {
          width: 100%;
          text-align: center;
          font-weight: 400;
          font-size: 1.5rem;
          color: #ffffff; }
          @media screen and (max-width: 1024px) {
            .case-table .case-item .case-item-buttons .action-buttons__text {
              font-size: .8rem; } }
      .case-table .case-item .case-item-buttons .isclone {
        background: #0076c0; }
      .case-table .case-item .case-item-buttons .isvisualization {
        background-color: lightgreen; }
      .case-table .case-item .case-item-buttons .isfavorited {
        background-color: red !important; }
      .case-table .case-item .case-item-buttons .isshared {
        background: #0303dd; }
  .case-table .case-item:hover {
    background: #eafbff; }

@media screen and (min-width: 1200px) {
  .project-modal-share {
    width: 60rem !important;
    max-width: 60rem !important; } }

.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.Collapsible {
  background-color: white; }

.Collapsible__contentInner {
  padding: 10px;
  border-top: 0;
  padding-left: 1.5rem; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.api-header {
  display: block;
  font-weight: 400;
  text-decoration: none;
  opacity: 1;
  color: #999999;
  position: relative;
  border: none !important;
  width: 100%; }
  .api-header:focus {
    outline: none !important; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  opacity: 1;
  color: #999999;
  position: relative;
  border: none !important;
  width: 100%; }
  .Collapsible__trigger:focus {
    outline: none !important; }
  .Collapsible__trigger:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f078";
    position: absolute;
    right: 10px;
    top: 1px;
    display: block;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .Collapsible__trigger.is-open:after {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
    border: none !important; }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black; }

.case-title {
  color: #0076c0;
  padding-bottom: 1rem; }

.no-left-margin {
  margin-left: -1.5rem !important; }

.transfer-ownership {
  display: flex;
  margin: 1rem 0rem;
  font-size: 0.9rem; }
  .transfer-ownership .transfer-ownership-select {
    width: 12rem; }
  .transfer-ownership .transfer-ownership-button {
    margin-left: 1rem;
    width: 11rem; }

.case-experiment {
  display: block;
  line-height: 20px;
  font-size: 14px;
  color: #b8b7ad;
  margin-bottom: 0 !important; }
  .case-experiment:last-of-type {
    margin-top: 16px; }
  .case-experiment__input {
    font-size: 16px;
    font-weight: 500;
    padding: 4px 7px;
    width: 85%; }
    .case-experiment__input:focus {
      outline: none !important; }

.preprocess-ul {
  -webkit-padding-start: 0px !important;
          padding-inline-start: 0px !important; }

.case-api-button {
  padding-top: 16px;
  padding-bottom: 16px; }
  .case-api-button__button {
    width: 13rem;
    background: #00caff 0% 0% no-repeat padding-box;
    height: 35px;
    color: white;
    font-size: 1rem;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 3px; }
    .case-api-button__button:hover {
      background: #00acd9; }

.score-metric {
  padding-top: 18px;
  font-weight: 600; }
  .score-metric:first-child {
    padding-top: 0px; }

.model-score {
  display: flex;
  justify-content: space-between;
  color: #000000;
  opacity: 1; }
  .model-score .score-left {
    color: #808080;
    padding: 2px 0; }
  .model-score .score-right {
    padding-right: 1rem;
    color: #0c191e;
    text-align: right;
    padding: 2px 0; }

.case-data {
  line-height: 20px;
  padding-bottom: 16px;
  display: flex; }
  .case-data.multiline {
    display: block; }
  .case-data__title {
    font-size: 14px;
    color: #b8b7ad;
    margin-right: 1rem; }
  .case-data__text {
    font-size: 16px;
    font-weight: 500; }
    .case-data__text.link {
      color: #637DD7; }
      .case-data__text.link:hover {
        cursor: pointer;
        text-decoration: underline; }

.for-scroll {
  padding-bottom: 100px; }

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px; }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47; }

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px !important;
  width: 36px !important; }

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7; }

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  box-shadow: 4px 5px 6px #00000029;
  border: 3px solid #efefef; }
  @media screen and (min-width: 1200px) {
    .bm-menu-wrap {
      width: 25% !important; } }

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 1.5rem;
  font-size: 1.15em; }

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white; }

/* Wrapper for item list */
/* Individual item */
.bm-item {
  display: inline-block; }

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.header-outer .product-header {
  background-color: #ffffff;
  color: #fff;
  min-height: 3.75rem;
  border-top: solid 5px #6A0DAD;
  box-shadow: 0 1px 0 rgba(12, 13, 14, 0.1), 0 1px 0.375rem rgba(59, 64, 69, 0.1);
  z-index: 999;
  margin-bottom: 0.1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .header-outer .product-header .logo {
    height: 3rem;
    margin-left: 1rem;
    margin-right: auto;
    cursor: pointer; }
  .header-outer .product-header .nav {
    padding: 15px;
    border-right: 1px solid #f2f2f2; }
    .header-outer .product-header .nav.active {
      color: #0076c0;
      text-decoration: underline; }
      .header-outer .product-header .nav.active a {
        color: #0076c0 !important; }
    .header-outer .product-header .nav .active {
      color: #0076c0;
      text-decoration: underline; }
    .header-outer .product-header .nav .nav-link {
      padding: 0; }
    .header-outer .product-header .nav .nav-text:hover {
      color: orange; }
    .header-outer .product-header .nav .help-form {
      width: 22rem;
      color: #000000;
      text-align: left; }
      .header-outer .product-header .nav .help-form .help-header {
        text-align: center;
        color: #646464;
        padding-bottom: 2rem; }
      .header-outer .product-header .nav .help-form .primary-button {
        padding: 8px 0; }
      .header-outer .product-header .nav .help-form.sent {
        padding: 0;
        margin-bottom: 0; }
    .header-outer .product-header .nav a {
      color: #646464;
      font-size: 15px;
      font-weight: 600;
      line-height: 21px; }

.product-space {
  background: inherit;
  flex: 1 1;
  padding-bottom: 0.5rem;
  min-height: 90vh;
  position: relative;
  overflow: auto; }

.default-setting-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem 3rem; }
  .default-setting-card .setting-header {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center; }

.setting-checkbox {
  margin-top: 1.2rem !important; }

.setting {
  overflow: visible;
  padding: 0.8rem 0; }
  .setting .input-with-default .default-value {
    margin-top: 0.5rem;
    color: gray;
    font-size: 0.8rem; }
  .setting input {
    padding-left: 0.5rem; }
  .setting .setting-name {
    display: inline-block;
    font-size: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
    color: #666; }
    .setting .setting-name.selected {
      color: #61B38E; }
  .setting .help-icon {
    height: 1rem;
    width: 1rem; }
  .setting .setting-input {
    width: 6.25rem; }
  .setting .setting-datepicker {
    width: 7.5rem; }
  .setting .compare {
    display: flex;
    align-items: center; }
    .setting .compare .compare-select {
      width: 5rem;
      margin-right: 1rem; }
    .setting .compare .compare-input {
      width: 10rem; }
  .setting .selector {
    width: 18.75rem; }
  .setting .slider {
    width: 20rem; }
  .setting .float-right {
    float: right; }
  .setting.singleline {
    display: flex;
    align-items: center;
    padding: 0.4rem 0; }
    .setting.singleline .react-datepicker-wrapper {
      float: right; }
    .setting.singleline .setting-unit {
      float: right; }
    .setting.singleline .setting-input {
      float: right;
      text-align: right; }
    .setting.singleline .right {
      flex: 4 1;
      margin-bottom: 0.5rem; }

.with-sidebar {
  background: inherit;
  display: flex;
  min-height: 700px;
  align-items: stretch; }
  @media screen and (min-width: 1000px) {
    .with-sidebar {
      margin: 2rem 0 0 2rem; } }
  .with-sidebar .sidebar {
    display: flex;
    position: relative;
    flex-direction: column;
    border: solid 0.125rem #E1E1E1;
    border-radius: 0.9375rem;
    background: white;
    width: 25rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 2rem;
    margin-top: 1rem;
    transition: margin-left 0.65s;
    overflow: auto; }
    .with-sidebar .sidebar .sidebar-close {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 1rem;
      margin-top: 1rem;
      background-color: #E9F4FF; }
    .with-sidebar .sidebar .sidebar-title {
      justify-self: center;
      align-self: center;
      padding: 2rem 0;
      background-color: #E9F4FF;
      width: 100%;
      text-align: center;
      color: #657889;
      font-weight: inherit !important; }
    .with-sidebar .sidebar .sidebar-content .sidebar-button {
      display: block;
      padding: .5rem;
      margin: .5rem;
      color: #657889;
      border-radius: 2rem; }
      .with-sidebar .sidebar .sidebar-content .sidebar-button:hover {
        background-color: #657889;
        color: #FFFFFF; }
    .with-sidebar .sidebar .sidebar-content .sidebar-icon {
      position: relative;
      background-color: #657889;
      color: #FFFFFF;
      border-radius: 50%;
      padding: .3rem;
      top: 0.125rem;
      font-size: 1.7rem !important;
      width: 1.7rem !important; }
    .with-sidebar .sidebar .sidebar-text {
      padding-left: .7rem;
      padding-top: .7rem; }
      .with-sidebar .sidebar .sidebar-text .collab-icon {
        background-color: #ffffff;
        color: #0076C0; }
      .with-sidebar .sidebar .sidebar-text .collab-button {
        border-radius: 2rem; }
        .with-sidebar .sidebar .sidebar-text .collab-button:hover {
          color: #0076C0; }
        .with-sidebar .sidebar .sidebar-text .collab-button:active {
          outline: none !important; }
        .with-sidebar .sidebar .sidebar-text .collab-button:focus {
          outline: none !important; }
      .with-sidebar .sidebar .sidebar-text .picky__input {
        border: none !important;
        margin-top: .4rem; }
        .with-sidebar .sidebar .sidebar-text .picky__input:active {
          outline: none !important; }
        .with-sidebar .sidebar .sidebar-text .picky__input:focus {
          outline: none !important; }
      .with-sidebar .sidebar .sidebar-text .collab-submit {
        text-align: right;
        justify-items: center; }
        .with-sidebar .sidebar .sidebar-text .collab-submit .btn {
          margin-top: .5rem;
          background-color: #007AF4;
          color: #FFFFFF; }
      .with-sidebar .sidebar .sidebar-text .edit-description-icon:hover {
        cursor: pointer;
        color: red; }
      .with-sidebar .sidebar .sidebar-text .edit-description-icon:active {
        outline: none !important; }
      .with-sidebar .sidebar .sidebar-text .edit-description-icon:focus {
        outline: none !important; }
      .with-sidebar .sidebar .sidebar-text .no-outline {
        border: solid #657889 1px;
        margin-bottom: .5rem; }
        .with-sidebar .sidebar .sidebar-text .no-outline:active {
          outline: none !important; }
        .with-sidebar .sidebar .sidebar-text .no-outline:focus {
          outline: none !important; }
    .with-sidebar .sidebar .collabs {
      padding-top: .8rem;
      list-style: none; }
      .with-sidebar .sidebar .collabs li {
        padding: .8rem .8rem 0 0;
        margin-left: -2rem;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .with-sidebar .sidebar .collabs .collab-remove-icon:hover {
        color: red; }
  .with-sidebar .main-content {
    flex: 1 1;
    flex-direction: column;
    background: inherit;
    overflow: hidden;
    margin-left: 0.5rem;
    margin-right: 2rem;
    margin-bottom: 1rem; }

.visualization {
  display: flex;
  height: 100%;
  padding-left: 0.9375rem; }
  .visualization .nav-outer-container {
    width: 200px;
    height: 100%; }
  .visualization .visualization-container {
    /*height: 100%;*/
    width: 100%;
    padding: 0.9375rem 0px 0px 0.9375rem;
    overflow-y: auto; }
    .visualization .visualization-container .loading-background {
      height: 100%;
      background: #FFFFFF; }

.api-page-container {
  display: grid;
  grid-template-columns: 1fr .175fr;
  justify-items: center;
  padding: 0 2rem; }
  .api-page-container .api-calls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 2rem;
    text-align: center;
    padding: rem 1rem; }
    .api-page-container .api-calls .api-calls-box {
      box-shadow: 0 3px 0.375rem rgba(0, 0, 0, 0.2);
      height: 200px;
      border-radius: 1px;
      transition: .3s;
      border-top: solid 5px;
      margin: 1.5rem 0; }
      .api-page-container .api-calls .api-calls-box .calendar-calls {
        padding-top: 1.5rem;
        font-size: 1.5rem; }
      .api-page-container .api-calls .api-calls-box .calls-number {
        font-size: 3rem; }
      .api-page-container .api-calls .api-calls-box.first {
        border-color: #00CAFF; }
      .api-page-container .api-calls .api-calls-box.second {
        border-color: #5A51DD; }
      .api-page-container .api-calls .api-calls-box.third {
        border-color: #F39B12; }

.faq .container {
  padding: 1rem 0; }
  @media screen and (min-width: 1500px) {
    .faq .container {
      max-width: 1500px; } }
  .faq .container .faq-header {
    text-align: center;
    padding: 30px 0;
    letter-spacing: 0;
    color: #646464;
    opacity: 1; }
  .faq .container .faq-main {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem 3rem;
    justify-items: center;
    align-items: center;
    margin-bottom: 6rem; }
    .faq .container .faq-main .faq-card {
      width: 100%;
      height: 185px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 2px 2px 2px #00000029;
      opacity: 1; }
      .faq .container .faq-main .faq-card .faq-card-img-div {
        height: 120px;
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #bfbfbf; }
        .faq .container .faq-main .faq-card .faq-card-img-div .faq-img {
          width: 80%;
          padding: 10px 0 15px; }
      .faq .container .faq-main .faq-card .faq-card-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        padding: 0 10px; }
        .faq .container .faq-main .faq-card .faq-card-bottom .faq-card-title {
          color: #3f3d56;
          opacity: 1;
          letter-spacing: 0; }
        .faq .container .faq-main .faq-card .faq-card-bottom .faq-card-link {
          text-decoration: underline;
          color: #007bff;
          background-color: transparent; }
          .faq .container .faq-main .faq-card .faq-card-bottom .faq-card-link:hover {
            color: #004a99;
            cursor: pointer; }

.faq-category .container {
  padding: 0 0 2rem;
  background: #ffffff 0% 0% no-repeat padding-box; }
  @media screen and (min-width: 1500px) {
    .faq-category .container {
      max-width: 1550px; } }
  @media screen and (min-width: 1700px) {
    .faq-category .container {
      max-width: 1650px; } }
  .faq-category .container .button-background {
    background: #f9f9f9;
    height: 80px;
    display: flex;
    align-items: center; }
    .faq-category .container .button-background .faq-back-button {
      padding-left: 1rem; }
      .faq-category .container .button-background .faq-back-button:hover {
        cursor: pointer; }
  .faq-category .container .faq-category-header {
    letter-spacing: 0;
    color: #3f3d56;
    opacity: 1;
    width: 100%;
    border-bottom: 1px solid #bfbfbf;
    padding-left: 20px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px; }
    .faq-category .container .faq-category-header__text {
      font-size: 28px; }
    .faq-category .container .faq-category-header .input-with-icon {
      width: 25rem;
      background: #bfbfbf;
      opacity: 1;
      margin-right: 1rem;
      border-color: #657889;
      border: none !important; }
      .faq-category .container .faq-category-header .input-with-icon input:focus {
        outline: none; }
  .faq-category .container .faq-category-main {
    display: flex; }
    .faq-category .container .faq-category-main .faq-category-left {
      padding: 2rem 0;
      box-sizing: border-box; }
      .faq-category .container .faq-category-main .faq-category-left .sticky {
        margin: 0;
        position: -webkit-sticky;
        position: sticky;
        top: 20px; }
      .faq-category .container .faq-category-main .faq-category-left .faq-category-tab {
        letter-spacing: 0;
        color: #2b333a;
        width: 20rem;
        opacity: 1;
        padding: 16px 0;
        border-radius: 8px; }
        .faq-category .container .faq-category-main .faq-category-left .faq-category-tab:hover {
          cursor: pointer; }
        .faq-category .container .faq-category-main .faq-category-left .faq-category-tab .faq-category-tab-text {
          padding-left: 1rem;
          color: #2b333a; }
        .faq-category .container .faq-category-main .faq-category-left .faq-category-tab .faq-subtopic {
          margin-top: 0.75rem;
          margin-left: 2rem;
          padding-left: 1rem;
          letter-spacing: 0;
          color: #43505c;
          border-radius: 5px;
          opacity: 1;
          margin-bottom: 0.5rem; }
          .faq-category .container .faq-category-main .faq-category-left .faq-category-tab .faq-subtopic .sub-a {
            color: #43505c !important; }
      .faq-category .container .faq-category-main .faq-category-left .active {
        background: #f6f9fd 0% 0% no-repeat padding-box;
        border-left: 5px solid #0072a9; }
    .faq-category .container .faq-category-main .faq-category-content {
      flex: 1 1;
      padding: 2rem;
      border-left: 1px solid #bfbfbf;
      line-height: 2.2;
      font-size: 1.2rem; }
      .faq-category .container .faq-category-main .faq-category-content h3 {
        font-size: 2rem; }
      .faq-category .container .faq-category-main .faq-category-content h4 {
        font-size: 1.5rem; }
      .faq-category .container .faq-category-main .faq-category-content h5 {
        font-size: 1.3rem; }
      .faq-category .container .faq-category-main .faq-category-content hr {
        margin-top: 4rem;
        margin-bottom: 3rem; }
      .faq-category .container .faq-category-main .faq-category-content table,
      .faq-category .container .faq-category-main .faq-category-content td,
      .faq-category .container .faq-category-main .faq-category-content th {
        border: 1px solid #ddd;
        text-align: left; }
      .faq-category .container .faq-category-main .faq-category-content table {
        border-collapse: collapse; }
      .faq-category .container .faq-category-main .faq-category-content th,
      .faq-category .container .faq-category-main .faq-category-content td {
        padding: 0.25rem; }
      .faq-category .container .faq-category-main .faq-category-content figcaption {
        text-align: center;
        text-justify: inter-word;
        margin: 0.75rem 7rem 0 7rem;
        font-size: 1rem;
        color: #3d465e; }

.parent-grid {
  display: flex;
  padding: 2rem 4rem;
  width: 100%;
  align-items: flex-start; }
  .parent-grid .main-page-left {
    display: flex;
    flex-direction: column;
    flex: 1 1; }
    .parent-grid .main-page-left .welcome-video-parent {
      display: flex;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #0000000f;
      border: 2px dashed #01caff;
      border-radius: 0.8rem;
      align-items: center;
      height: 22rem;
      margin-bottom: 2rem; }
      .parent-grid .main-page-left .welcome-video-parent .welcome-video-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .parent-grid .main-page-left .welcome-video-parent .welcome-video-right {
        margin: 2rem 1rem;
        flex: 1 1; }
        .parent-grid .main-page-left .welcome-video-parent .welcome-video-right video {
          width: 100%;
          max-height: 21rem; }
    .parent-grid .main-page-left .charts {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; }
      .parent-grid .main-page-left .charts .chart-container {
        background-color: white;
        box-shadow: 0px 1px 6px #0000000f;
        border: 1px solid #bfbfbf;
        border-radius: 0.8rem;
        height: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 48%;
        margin-bottom: 2rem; }
        .parent-grid .main-page-left .charts .chart-container .header {
          padding: 1rem;
          font-size: 1.2rem;
          text-align: center;
          border-bottom: 1px solid gray;
          align-self: stretch; }
        .parent-grid .main-page-left .charts .chart-container .chart {
          width: 95%;
          margin: 1rem 0;
          flex: 1 1; }
  .parent-grid .main-page-right {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #0000000f;
    border: 1px solid #bfbfbf;
    border-radius: 0.8rem;
    margin-left: 2rem;
    padding-right: 0.2rem;
    width: 25rem;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 10rem; }
    .parent-grid .main-page-right .no-activity {
      margin: 2rem 0;
      font-size: 1.3rem;
      color: gray;
      width: 100%;
      text-align: center; }
    .parent-grid .main-page-right .activity-cards {
      display: flex;
      flex-direction: column;
      padding: 0 1.5rem; }
      .parent-grid .main-page-right .activity-cards .change-date {
        color: gray;
        text-align: center;
        margin-bottom: 1rem; }
      .parent-grid .main-page-right .activity-cards .activity-card {
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        margin-bottom: 1rem; }
        .parent-grid .main-page-right .activity-cards .activity-card .right {
          margin-left: 1rem;
          flex: 1 1;
          display: flex;
          flex-direction: column; }
          .parent-grid .main-page-right .activity-cards .activity-card .right .header {
            display: flex;
            margin-bottom: 0.5rem; }
            .parent-grid .main-page-right .activity-cards .activity-card .right .header .text {
              font-weight: bold; }
            .parent-grid .main-page-right .activity-cards .activity-card .right .header .time {
              margin-left: auto;
              color: gray; }
          .parent-grid .main-page-right .activity-cards .activity-card .right .description .activity-link {
            display: inline;
            color: #637DD7;
            text-decoration: underline;
            cursor: pointer; }
    .parent-grid .main-page-right .activity-tab-selector {
      padding: 1.5rem;
      display: flex; }
      .parent-grid .main-page-right .activity-tab-selector .tab-option {
        margin-right: 1.5rem;
        cursor: pointer;
        outline: 0;
        box-shadow: none;
        color: gray; }
        .parent-grid .main-page-right .activity-tab-selector .tab-option .text {
          font-size: 1.1rem; }
        .parent-grid .main-page-right .activity-tab-selector .tab-option.selected {
          color: #01caff;
          position: relative;
          padding-bottom: 0.5rem; }
          .parent-grid .main-page-right .activity-tab-selector .tab-option.selected .text::after {
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 1px;
            border-width: 0 0 2px;
            border-style: solid; }
  .parent-grid .light-blue-highlight {
    text-align: center;
    font: Medium 24px/40px Roboto;
    letter-spacing: 0;
    color: #01caff;
    margin-bottom: 2rem; }
  .parent-grid .prompt {
    color: gray; }
  .parent-grid .prompt-upgrade {
    margin-top: 1rem;
    color: gray;
    display: flex;
    align-items: center; }
    .parent-grid .prompt-upgrade .contact-us {
      color: #637DD7;
      margin-right: 0.4rem;
      cursor: pointer; }
      .parent-grid .prompt-upgrade .contact-us:hover {
        text-decoration: underline; }
    .parent-grid .prompt-upgrade .tip-icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      bottom: 3rem;
      right: 3rem;
      background-color: #f8cb04;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem; }
      .parent-grid .prompt-upgrade .tip-icon .tip-logo {
        width: 1rem;
        height: 1rem; }
        .parent-grid .prompt-upgrade .tip-icon .tip-logo:hover {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); }

.link {
  cursor: pointer;
  color: #3c86ff;
  text-decoration: underline; }
  .link:hover {
    color: #0047bc; }

.my-account-container {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  opacity: 1;
  margin: 4rem 0; }
  .my-account-container .account-header {
    width: 100%;
    height: 5rem;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    font-size: 1.5rem;
    color: #605b5b; }
  .my-account-container .account-grid {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    width: 100%;
    grid-template-rows: 5rem 5rem;
    justify-items: center; }
    .my-account-container .account-grid .details-row {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center; }
      .my-account-container .account-grid .details-row:nth-child(-n + 3) {
        border-bottom: 1px solid #dfdfdf; }
      .my-account-container .account-grid .details-row.column1 {
        color: #797c80;
        padding-left: 2rem; }
      .my-account-container .account-grid .details-row.column2 {
        align-self: center; }
        .my-account-container .account-grid .details-row.column2 .alert {
          margin-bottom: 0; }
      .my-account-container .account-grid .details-row.column3 {
        justify-content: flex-end;
        padding-right: 4rem; }
        .my-account-container .account-grid .details-row.column3 .account-edit:hover {
          color: red;
          cursor: pointer; }

.subscription-container {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  opacity: 1;
  margin: 4rem 0;
  margin-bottom: 5rem; }
  .subscription-container .subscription-header {
    width: 100%;
    height: 5rem;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 4rem;
    font-size: 1.5rem;
    color: #605b5b;
    justify-content: space-between; }
    .subscription-container .subscription-header .member-plan {
      letter-spacing: 0px;
      color: #0076c0;
      display: flex;
      align-items: center;
      font-size: 1rem; }
      .subscription-container .subscription-header .member-plan .star {
        margin-right: 6px; }
      .subscription-container .subscription-header .member-plan:hover {
        text-decoration: underline;
        cursor: pointer; }
  .subscription-container .subscription-grid {
    display: grid;
    grid-template-columns: 20% 40% 20% 20%;
    width: 100%;
    grid-template-rows: 5rem 5rem 5rem;
    justify-items: center; }
    .subscription-container .subscription-grid .details-row {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center; }
      .subscription-container .subscription-grid .details-row:nth-child(-n + 9) {
        border-bottom: 1px solid #dfdfdf; }
      .subscription-container .subscription-grid .details-row.column1 {
        color: #797c80;
        padding-left: 2rem; }
      .subscription-container .subscription-grid .details-row.column3 {
        color: #797c80; }
      .subscription-container .subscription-grid .details-row.column4 {
        color: #797c80; }

.error-color {
  color: #DE7A83; }

.warning-color {
  color: #ffa600; }

.primary-color {
  color: #637DD7; }

.success-color {
  color: #61B38E; }

.disabled-color {
  color: gray; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.autopilot-panel {
  box-shadow: 2px 2px 6px #0000000F;
  border: 1px solid #C8C8C8;
  background-color: white;
  padding: 2rem;
  font-size: 1.2rem;
  color: #637DD7;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem calc(min(20rem, 20%)); }
  .autopilot-panel .autopilot-image {
    margin-top: 3rem;
    height: 10rem; }

.modal-usage-warning .modal-title {
  width: 100%;
  text-align: center; }

.modal-usage-warning .usage-warning-header {
  color: #ffa600; }

.modal-usage-warning .usage-warning-action-bar {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .modal-usage-warning .usage-warning-action-bar .not-interested {
    color: #637DD7;
    cursor: pointer;
    margin-right: 2rem; }

.circle-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  font-size: 0.8rem; }

.link-text {
  color: #637DD7;
  cursor: pointer; }
  .link-text:hover {
    text-decoration: underline;
    font-weight: bold; }

.default-card {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1); }
  .default-card .default-card-header {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center; }

.input-search {
  max-width: 40rem; }

.busy-overlay {
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(221, 221, 221, 0.5);
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.page-tip {
  position: fixed;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  bottom: 3rem;
  right: 3rem;
  background-color: #f8cb04;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  -webkit-animation: pulse-page-tip 2s infinite;
          animation: pulse-page-tip 2s infinite; }
  .page-tip .logo {
    cursor: pointer;
    width: 2.2rem;
    height: 2.2rem; }
    .page-tip .logo:hover {
      -webkit-transform: scale(1.3);
              transform: scale(1.3); }
  .page-tip .tip-box {
    cursor: default;
    visibility: visible;
    width: 28rem;
    height: 19rem;
    text-align: left;
    border-radius: 6px;
    padding: 1rem;
    position: absolute;
    z-index: 1000;
    bottom: 100%;
    left: 50%;
    margin-left: -24rem;
    margin-bottom: 2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 6px 5px 6px #0000000f;
    border: 1px solid #cbcbcb;
    opacity: 0.95;
    transition: opacity 1s; }
    .page-tip .tip-box .tip-header {
      display: flex;
      justify-content: space-between; }
      .page-tip .tip-box .tip-header .tip-header-text {
        font: 1.5rem;
        color: #3d465e;
        padding: 0.25rem; }
      .page-tip .tip-box .tip-header .tip-close-button {
        width: 1rem;
        height: 1rem; }
        .page-tip .tip-box .tip-header .tip-close-button:hover {
          -webkit-transform: scale(1.25);
                  transform: scale(1.25); }
    .page-tip .tip-box .hr {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; }
    .page-tip .tip-box .pagination {
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
      position: absolute;
      bottom: 0.5rem;
      right: 0.5rem; }
      .page-tip .tip-box .pagination .left {
        margin-left: 2rem; }
      .page-tip .tip-box .pagination .right .clickable {
        color: #0077ff;
        padding: 0.5rem;
        text-decoration: underline; }
      .page-tip .tip-box .pagination .right .nonclickable {
        color: #cacdd1;
        padding: 0.5rem;
        text-decoration: underline; }
  .page-tip .tip-box::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 24rem;
    margin-left: -0.7rem;
    border-width: 0.7rem;
    border-style: solid;
    border-color: #fefefe transparent transparent transparent;
    transition: 2s; }

.page-tip:hover {
  -webkit-animation: none;
          animation: none; }

@-webkit-keyframes pulse-page-tip {
  0% {
    box-shadow: 0 0 0 10px #f8cb0439; }
  70% {
    box-shadow: 0 0 0 10px #00000000; }
  100% {
    box-shadow: 0 0 0 10px #f8cb0439; } }

@keyframes pulse-page-tip {
  0% {
    box-shadow: 0 0 0 10px #f8cb0439; }
  70% {
    box-shadow: 0 0 0 10px #00000000; }
  100% {
    box-shadow: 0 0 0 10px #f8cb0439; } }

.control-disabled {
  color: grey; }
  .control-disabled:hover {
    cursor: not-allowed; }

.spin-reverse {
  animation-direction: reverse; }

.banner {
  position: absolute;
  z-index: 50;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem 1.5rem 0.3rem 0.5rem;
  color: white;
  background: #61B38E;
  max-width: 40rem;
  max-height: 5rem;
  overflow: auto;
  top: 8.5rem;
  right: 0.5rem; }
  .banner .icon {
    position: absolute;
    top: 0.5rem;
    right: 0.2rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer; }
  .banner.info {
    background-color: #637DD7; }
  .banner.warning {
    background-color: #ffa600; }
  .banner.error {
    background-color: #DE7A83; }

.pagination {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .pagination .pagination-inner {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 0;
    border-radius: 0.5rem;
    margin-bottom: 0; }
    .pagination .pagination-inner li {
      border-radius: 0;
      background-color: white; }
      .pagination .pagination-inner li a {
        position: relative;
        float: left;
        padding: 0.2rem 0.4rem;
        text-decoration: none;
        color: #333;
        border: 1px solid #ddd;
        margin-left: -1px; }
      .pagination .pagination-inner li.active {
        background-color: #637DD7; }
        .pagination .pagination-inner li.active a {
          color: white; }
      .pagination .pagination-inner li.disabled a {
        color: lightgray;
        cursor: not-allowed; }
  .pagination .pagination-icon {
    margin-right: 0.5rem;
    cursor: pointer; }
    .pagination .pagination-icon.disabled {
      cursor: inherit;
      color: gray; }

.test-container {
  width: 100%; }

.list-container-default {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: space-around;
  flex-wrap: wrap;
  flex: 1 1; }
  .list-container-default div {
    margin: 0 1rem 1rem 1rem; }

.list-container-grid {
  width: 100%;
  display: grid;
  align-items: center;
  align-content: space-around;
  flex-wrap: wrap; }
  .list-container-grid div {
    margin: 1rem; }

.project-container {
  display: flex;
  max-width: 100%; }
  .project-container .project-container-right {
    flex: 1 1; }

.input-with-icon {
  position: relative;
  width: 100%; }
  .input-with-icon input[type="text"] {
    padding: 0.3rem 2.5rem 0.3rem 0.5rem;
    width: 100%; }
  .input-with-icon input[type="number"] {
    padding: 0.2rem 1.8rem 0.2rem 0.5rem;
    width: 100%; }
  .input-with-icon .icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: gray;
    cursor: pointer; }

.status-message {
  display: inline-block;
  border-radius: 1000px;
  padding: 0.1rem 0.5rem;
  font-weight: bold; }
  .status-message.with-border {
    border: 0.125rem solid black; }
  .status-message.disabled-bg {
    background-color: gray;
    color: rgba(67, 66, 93, 0.4); }
  .status-message.error-bg {
    background-color: rgba(222, 122, 131, 0.4);
    color: #DE7A83; }
  .status-message.warning-bg {
    background-color: rgba(255, 166, 0, 0.4);
    color: #ffa600; }
  .status-message.primary-bg {
    background-color: rgba(99, 125, 215, 0.4);
    color: #637DD7; }
  .status-message.success-bg {
    background-color: rgba(97, 179, 142, 0.4);
    color: #61B38E; }

.my-tooltip {
  z-index: 100;
  max-width: 20rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3); }
  .my-tooltip .multi-line {
    color: gray;
    text-align: left; }

.popover-wrapper {
  position: relative;
  display: inline-block;
  overflow: visible; }
  .popover-wrapper .popover-content {
    opacity: 0;
    display: none;
    position: absolute;
    cursor: default;
    top: -10px;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    background-color: #f9f9f9;
    color: black;
    width: auto;
    flex-direction: column;
    box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3); }
    .popover-wrapper .popover-content::after {
      display: none;
      position: absolute;
      z-index: -1;
      content: "";
      right: calc(50% - 10px);
      top: 100%;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #f9f9f9 transparent transparent transparent; }
    .popover-wrapper .popover-content.warning {
      border-top: 5px solid #ffa600; }
    .popover-wrapper .popover-content.error {
      border-top: 5px solid #DE7A83; }
    .popover-wrapper .popover-content.info {
      border-top: 5px solid #637DD7; }
    .popover-wrapper .popover-content.show {
      z-index: 10;
      opacity: 1;
      display: flex; }
      .popover-wrapper .popover-content.show::after {
        display: block; }
      .popover-wrapper .popover-content.show.hide-arrow::after {
        display: none; }
    .popover-wrapper .popover-content.right-aligned {
      left: auto;
      right: 0;
      -webkit-transform: translate(0, -100%);
              transform: translate(0, -100%); }
      .popover-wrapper .popover-content.right-aligned::after {
        right: 4rem; }
      .popover-wrapper .popover-content.right-aligned.popover-below {
        top: 100%;
        -webkit-transform: translate(0, 10px);
                transform: translate(0, 10px); }
        .popover-wrapper .popover-content.right-aligned.popover-below::after {
          top: -0.9375rem;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #bfbfbf transparent; }
    .popover-wrapper .popover-content .message {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      min-width: 20rem;
      margin: 1rem; }
      .popover-wrapper .popover-content .message .icon {
        color: #ffa600;
        margin-right: 1rem; }
    .popover-wrapper .popover-content .action-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .popover-wrapper .popover-content .action-bar .action {
        font-weight: bold;
        margin: 0 0.5rem 0 1rem;
        cursor: pointer;
        color: #637DD7; }
  .popover-wrapper.hoverable:hover .popover-content {
    z-index: 10;
    opacity: 1;
    display: flex; }
    .popover-wrapper.hoverable:hover .popover-content::after {
      display: block; }

.img-crossfade {
  position: relative;
  width: 8rem;
  height: 8rem; }
  .img-crossfade img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: white; }
  .img-crossfade .top {
    -webkit-animation-name: crossfade;
            animation-name: crossfade;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-direction: alternate;
            animation-direction: alternate; }

.file-upload {
  background-color: rgba(99, 125, 215, 0.05);
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  border-width: 0.125rem;
  border-radius: 0.125rem;
  border-color: #ddd;
  border-style: dashed;
  outline: none;
  padding: 1rem;
  width: 100%; }
  .file-upload.success {
    border-color: #61B38E; }
  .file-upload.error {
    border-color: #DE7A83; }
  .file-upload .uploading-text {
    margin-left: auto;
    margin-right: 2rem;
    color: #637DD7; }
  .file-upload .file-upload-error-icon {
    color: #DE7A83;
    margin-right: 0.3rem; }
  .file-upload .file-upload-error-text {
    text-decoration: underline;
    cursor: pointer;
    color: #DE7A83; }
    .file-upload .file-upload-error-text .file-upload-error-popover {
      padding: 1rem;
      min-width: 20rem;
      max-height: 15rem;
      overflow-y: auto;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .file-upload .file-upload-error-text .file-upload-error-popover .file-upload-error-popover-header {
        display: flex;
        align-items: center;
        color: #DE7A83;
        margin-bottom: 1rem; }
  .file-upload .file-upload-status-icon {
    margin-right: 1rem;
    width: 2rem;
    height: 2rem; }
  .file-upload .file-upload-progress {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem; }
    .file-upload .file-upload-progress .CircularProgressbar-text {
      font-size: 1.5rem !important; }
  .file-upload .circle-text {
    border: 2px solid #555;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .file-upload .file-uploaded-icon {
    color: #61B38E; }
  .file-upload .file-info {
    display: flex;
    flex-direction: column;
    margin-right: 1rem; }
    .file-upload .file-info .file-name {
      font-weight: bold;
      font-size: 1.2rem; }
    .file-upload .file-info .file-type {
      color: gray;
      font-size: 0.8rem; }
      .file-upload .file-info .file-type .download-template {
        margin-left: 0.5rem;
        text-transform: uppercase; }
  .file-upload .file-upload-utlity-icons {
    color: gray;
    margin-left: auto;
    margin-right: 1rem; }
    .file-upload .file-upload-utlity-icons .icon {
      font-size: 1.2rem;
      margin-left: 0.8rem;
      cursor: pointer; }
  .file-upload .file-upload-zone {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 1.25rem; }
    .file-upload .file-upload-zone .browse {
      color: gray;
      border: 1px solid gray;
      padding: 0.5rem;
      margin-left: 0.5rem; }
    .file-upload .file-upload-zone .upload-icon {
      color: #637DD7;
      margin-right: 0.5rem; }
    .file-upload .file-upload-zone.active {
      background-color: rgba(99, 125, 215, 0.3);
      transition: border .2s ease-in-out; }
    .file-upload .file-upload-zone.disabled {
      color: gray;
      cursor: not-allowed; }
      .file-upload .file-upload-zone.disabled .upload-icon {
        color: gray; }
  .file-upload .select-type {
    min-width: 10rem;
    margin-left: 0.5rem; }

.regular-checkbox-span {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.2rem;
  cursor: pointer; }
  .regular-checkbox-span label {
    margin: 0; }
  .regular-checkbox-span .regular-checkbox {
    cursor: pointer;
    display: none;
    margin-right: 0.5rem; }
  .regular-checkbox-span .regular-checkbox + label {
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 0.125rem rgba(0, 0, 0, 0.05), inset 0px -0.9375rem 10px -12px rgba(0, 0, 0, 0.05);
    padding: 0.5625rem;
    border-radius: 3px;
    display: flex;
    align-items: center;
    position: relative; }
  .regular-checkbox-span .regular-checkbox + label:active, .regular-checkbox-span .regular-checkbox:checked + label:active {
    box-shadow: 0 1px 0.125rem rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1); }
  .regular-checkbox-span .regular-checkbox:checked + label {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 0.125rem rgba(0, 0, 0, 0.05), inset 0px -0.9375rem 10px -12px rgba(0, 0, 0, 0.05), inset 0.9375rem 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7; }
  .regular-checkbox-span .regular-checkbox:checked + label:after {
    content: '\2714';
    font-size: 0.875rem;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #61B38E; }
  .regular-checkbox-span .big-checkbox + label {
    padding: 18px; }
  .regular-checkbox-span .big-checkbox:checked + label:after {
    font-size: 28px;
    left: 0.375rem; }
  .regular-checkbox-span .regular-checkbox-text {
    white-space: nowrap;
    color: gray; }

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer; }

@-webkit-keyframes crossfade {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  51% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes crossfade {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  51% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.membership {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 1500px) {
    .membership .container {
      max-width: 1500px; } }
  @media screen and (min-width: 1600px) {
    .membership .container {
      max-width: 1600px; } }
  .membership .container .memb-content {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    margin-top: rem; }
    .membership .container .memb-content .col-container {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #d8d8d8;
      width: 100%;
      border-radius: 8px;
      position: relative; }
      .membership .container .memb-content .col-container .best-value {
        width: 8rem;
        position: absolute;
        top: 1rem;
        left: -1.2rem; }
      .membership .container .memb-content .col-container.no-border-radius {
        border-radius: 0px; }
      .membership .container .memb-content .col-container .memb-attr {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        height: 6rem;
        justify-content: center;
        padding-left: 1rem;
        font: Medium 14px/19px Roboto;
        letter-spacing: 0.7px;
        color: #010e2a; }
      .membership .container .memb-content .col-container .memb-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        height: 6rem;
        justify-content: center;
        font-size: 1.3rem;
        color: #ff5e14;
        font-weight: bold; }
        .membership .container .memb-content .col-container .memb-row .col-1-title {
          background: #0076c0 0% 0% no-repeat padding-box;
          width: 100%;
          height: 8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font: Bold 24px/35px Poppins;
          letter-spacing: 0px;
          color: #ffffff; }
        .membership .container .memb-content .col-container .memb-row .col-title {
          margin-top: 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .membership .container .memb-content .col-container .memb-row .col-title .col-2-title-text {
            padding-top: 0.5rem;
            font: bold 24px/35px Poppins;
            letter-spacing: 0px;
            color: #010e2a; }
        .membership .container .memb-content .col-container .memb-row .memb-check {
          display: flex;
          align-items: center;
          border-left: 1px solid #d3d3d3;
          width: 100%;
          justify-content: center;
          height: 100%;
          letter-spacing: 2.49px;
          font-size: 1rem; }
          .membership .container .memb-content .col-container .memb-row .memb-check__icon {
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            background: #459bd1 0% 0% no-repeat padding-box;
            border-radius: 25px;
            color: white;
            text-align: center;
            margin-top: 0.5rem;
            padding-top: 4px; }
      .membership .container .memb-content .col-container:last-child {
        border: 2px dashed #ff5e14; }
  .membership .memb-contact {
    margin-top: 1rem;
    align-self: center;
    padding: 0.75rem 2rem;
    background: #ff5e1400 0% 0% no-repeat padding-box;
    border: 2px solid #ff5e14;
    border-radius: 6px;
    font-size: 1.5rem;
    color: #ff5e14;
    font: Medium 16px/25px Poppins;
    letter-spacing: 0px; }
    .membership .memb-contact:hover {
      cursor: pointer;
      background: #ff5e14 0% 0% no-repeat padding-box;
      color: #ffffff; }
    .membership .memb-contact.memb-business {
      background: #ff5e14 0% 0% no-repeat padding-box;
      color: #ffffff; }
      .membership .memb-contact.memb-business:hover {
        background: #ff9261;
        border-color: #ff9261; }

.qri-admin-page {
  background-color: white; }
  .qri-admin-page .qri-admin-header {
    background-color: inherit;
    width: 100%;
    height: 90px;
    position: relative; }
    .qri-admin-page .qri-admin-header .right {
      color: #4d4f5c;
      margin-right: 2rem;
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      display: flex;
      align-items: center; }
      .qri-admin-page .qri-admin-header .right .icon-download {
        font-size: 1.5rem;
        margin-left: 1rem;
        cursor: pointer; }
    .qri-admin-page .qri-admin-header .header {
      font-size: 1.5rem;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .qri-admin-page .qri-admin-container {
    margin-top: 0; }
    .qri-admin-page .qri-admin-container .table-preprocessing {
      margin-top: 0 !important; }
      .qri-admin-page .qri-admin-container .table-preprocessing .delete {
        color: red; }
        .qri-admin-page .qri-admin-container .table-preprocessing .delete:hover {
          color: #ff8080;
          text-decoration: underline; }
      .qri-admin-page .qri-admin-container .table-preprocessing .blue-hyperlink {
        color: #0000ee; }
        .qri-admin-page .qri-admin-container .table-preprocessing .blue-hyperlink:hover {
          color: #6f6fff;
          text-decoration: underline;
          cursor: pointer; }
      .qri-admin-page .qri-admin-container .table-preprocessing .table-wrap {
        min-height: 30rem;
        width: 98%;
        margin: 0 1%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); }

.create-user-form {
  width: 100%; }
  .create-user-form .create-user-textbox {
    width: 100%;
    padding: 0.2rem 1rem; }

.help-modal-container.modal-dialog {
  max-width: 75rem !important;
  width: 80% !important;
  margin: 0.5rem auto; }

.help-modal-container .modal-body {
  padding: 0;
  max-height: 98vh; }

.help-modal-container .help-img-container {
  width: 100%; }
  .help-modal-container .help-img-container .help-pagi {
    padding: 0.5rem 1rem 1rem 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .help-modal-container .help-img-container .help-pagi .pagi-container {
      align-items: center;
      border-radius: 50%;
      background-color: grey;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center; }
      .help-modal-container .help-img-container .help-pagi .pagi-container:hover {
        background-color: #a6a6a6;
        cursor: pointer; }
    .help-modal-container .help-img-container .help-pagi .empty-div {
      height: 2rem;
      width: 2rem; }
  .help-modal-container .help-img-container .help-img {
    width: 100%; }

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Regular.3e1af3ef.ttf) format("truetype"); }

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Bold.ee7b96fa.ttf) format("truetype");
  font-weight: bold; }

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Medium.d0884059.ttf) format("truetype");
  font-style: medium; }

body,
html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-y: scroll; }

html {
  font-size: 16px; }

@media (max-width: 900px) {
  html {
    font-size: 14px; } }

@media (max-width: 400px) {
  html {
    font-size: 12px; } }

@media (max-width: 300px) {
  html {
    font-size: 8px; } }

body {
  margin: 0;
  padding: 0;
  font-family: Roboto; }

#app {
  color: #4d4f5c;
  font-size: 12px;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

#outer-container {
  margin: 0rem;
  background-color: #f9f9f9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  #outer-container h1,
  #outer-container h2,
  #outer-container h3,
  #outer-container h4,
  #outer-container h5,
  #outer-container h6 {
    font-weight: 600; }
  #outer-container div {
    font-family: "Roboto"; }

.required {
  color: red; }

