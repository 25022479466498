@import "../colors.scss";

.decline-executing-panel {
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
	border-radius: 1rem;
	background: white;
	display: flex;
	align-items: stretch;
	margin-left: 2rem;
	margin-right: 2rem;
	min-height: 20rem;
	.left {
		width: 40%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-right: 1px solid gray;
		.executing-header {
			color: $primary-color;
			font-weight: bold;
			font-size: 1.2rem;
			margin-bottom: 1rem;
		}
		.dca-progress {
			width: 90%;
			margin-bottom: 0.5rem;
		}
		.executed-header {
			color: $success-color;
			font-size: 1.8rem;
			font-weight: bold;
			margin-bottom: 2rem;
		}
	}
	.right {
		width: 60%;
		display: flex;
		flex-direction: column;
		padding: 2rem;
		.header {
			align-self: center;
			color: gray;
			font-size: 1.3rem;
			margin-bottom: 1.5rem;
		}
		.dca-tip {
			display: flex;
			align-items: center;
			margin-bottom: 1rem;
			.dca-tip-msg {
				margin-left: 1rem;
			}
		}
	}
}

.table-well {
	margin: 2rem 5%;
	width: 90%;
	th,td{
		font-size: 0.9rem;
		white-space: nowrap;
	}
}

.well-qc-panel {
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
	border-radius: 1rem;
	padding: 1rem;
	margin: 0 5%;
	background: white;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: visible;
	.qc-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		.qc-header-prompt {
			color: $primary-color;
		}
		.qc-header-error {
			color: $error-color;
			font-size: 1rem;
			font-style: italic;
		}
	}
}

.well-qc-chart {
	width: 90%;
	align-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: visible;
	.plot-well-dca-qc {
		height: 35rem;
		width: 100%;
	}
	.plot-typecurve-qc {
		height: 25rem;
		width: 100%;
	}
	.event-dot {
		background-color: rgba($success-color, 0.5);
		border-radius: 50%;
		width: 2rem;
		height: 2rem;
		z-index: 10;
		position: absolute !important;
		transform: translate(-50%, -50%);
		.popover-content {
			padding: 0 1rem;
			min-width: 15rem; 
			align-items: center;
			.delete-event-icon {
				color: $error-color;
				position: absolute;
				top:0;
				right:0;
				margin-right: 2rem;
				margin-top: 0.5rem;
				cursor: pointer;
			}
			.qc-prompt {
				margin-right: 1.8rem;
				margin-top: 1rem;
				margin-bottom: 0.5rem;
			}
			.qc-dca-parameters-bar {
				width: 100%;
				margin-bottom: 1rem;
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				.qc-dca-parameter {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					.setting-input {
						width: 5rem;
						margin: 0 0.5rem;
						text-align: center;
						&::-webkit-inner-spin-button { 
							opacity: 1;
						}
						&::-webkit-outer-spin-button { 
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

.pick-mode-panel {
	background-color:white;
	margin: 5rem calc(min(20%, 20rem));
	margin-bottom: 0;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid #C8C8C8;
	box-shadow: 2px 2px 6px #0000000F;
	.header {
		font-size: 1.6rem;
		color: #555;
		margin-bottom: 1rem;
		font-weight: bold;
	}
	.prompt {
		.mode-name {
			color: $primary-color;
			font-weight: bold;
			margin-right: 0.1rem;
		}
	}
	.mode-cards {
		display: flex;
		margin-top: 2rem;
		.mode-card {
			background-color: white;
			border: 1px solid #E5E5E5;
			border-top: 3px solid $primary-color;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 1.3rem;
			font-weight: bold;
			width: 15rem;
			height: 15rem;
			color: $primary-color;
			border-radius: 0.2rem !important;
			&:hover {
				box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
				transform: translateY(-0.2rem);
				background-color: #e9f4ff;
				cursor: pointer;
			}
			.mode-img {
				height: 8rem;
				width: 9rem;
				margin-bottom: 1rem;
			}

		}
	}
}

.decline-main {
	background: inherit;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	.decline-panel {
		display: flex;
		align-self: flex-start;
		position: relative;
		flex-direction: column;
		border: solid 0.125rem #e1e1e1;
		border-radius: 0.9375rem;
		background: white;
		width: 22.5rem;
		margin-bottom: 1rem;
		margin-left: 2rem;
		margin-right: 2rem;
		transition: width ease-out 0.1s;

		&.panel-collapsed {
			width: 2.5rem;
			align-items: center;
			padding: 1rem 0;
			transition: width ease-out 0.2s;
			.icon-collapsed {
				width: 1.5rem;
				height: 1.5rem;
				margin: 1.5rem 0.5rem;
			}
		}

		.decline-panel-header {
			height: 90px;
			border-bottom: solid 0.125rem #e1e1e1;
			border-radius: 0.9375rem 0.9375rem 0 0;
			overflow: hidden;
		}
		.decline-panel-content {
			position: relative;
			min-height: 37rem;
			max-height: 70rem;
			overflow-y: auto;
			flex: 1, 1;
			.dcarun-setting-panel {
				padding: 1rem 1.5rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				.setting-prompt {
					font-size: 0.9rem;
					color: gray;
					margin-bottom: 1rem;
					.number-selected-wells {
						color: $success-color;
						font-size: 1.3rem;
						font-weight: bold;
					}
				}
			}
		}
		.decline-panel-toggle {
			width: 1.5rem;
			height: 1.5rem;
			position: absolute;
			top: 50%;
			right: 0;
			z-index: 10;
			transform: translate(50%, -50%);
		}
	}

	.decline-main-content {
		flex: 1;
		flex-direction: column;
		background: inherit;
		overflow: hidden;
		margin-left: 0.5rem;
		margin-right: 2rem;
		margin-bottom: 1rem;
		position: relative;
		
		.customize-setting-panel {
			background: #FFFFFF 0% 0% no-repeat padding-box;
			border: 1px solid #CBCBCB;
			border-radius: 2rem;
			margin-top: 6rem;
			background-color: white;
			padding: 5rem 2rem;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			.left {
				width: 40%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				.prompt {
					font-size: 1rem;
					color: gray;
					text-align: center;
				}
				.header {
					font-size: 2rem;
					color: $primary-color;
					margin-bottom: 2rem;
				}
			}
		}
		.status-bar {
			display: flex;
			justify-content: center;
			margin-bottom: 1rem;
			.status-wells {
				margin: 0 1rem;
				background-color: white;
				border-radius: 1rem;
				box-shadow: .1rem .1rem .1rem rgba(0, 0, 0, .2);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				cursor: pointer;
				&.selected {
					border: 3px solid;
					cursor: default;
				}
				&.primary {
					border-color: $primary-color;
				}
				&.error {
					border-color: $error-color;
				}
				&.success {
					border-color: $success-color;
				}
				.upper {
					width:100%;
					background-color: #F5F5FA;
					display:flex;
					align-items: center;
					justify-content: center;
					height: 3rem;
					.icon{
						font-size: 1.5rem;
					}
				}
				.lower {
					text-align: center;
					padding: 0.5rem 1rem;
					.number {
						font-size: 1.3rem;
						font-weight: bold;
					}
				}
			}
		}
	}

	.decline-log {
		margin-left: 1.8rem;
		margin-right: 1.8rem;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		text-align: center;
		color: black;
		opacity: 0.6;
		.logHeader {
			font: 1.0rem/1.5rem;
			font-weight: bold;
			letter-spacing: 0.01rem;
			text-align: left;
			margin-bottom: 0.65rem;
		}
		.date {
			margin-top: 1.5rem;
			margin-bottom: 1.5rem;
			text-align: center;
		}
		.log-detail {
			padding: 0.95rem;
			border-radius: 0.2rem;
			.time-row {
				font: 0.85rem/1.2rem;
				display: flex;
				justify-content: space-between;
				margin-bottom: 0.1rem;
				.timestamp {
					float: left;
				}
				.timespan {
					float: right;
				}
			}
			.description {
				font: 0.85rem/1.2rem;
				text-align: left;
				.dot {
					height: 0.3rem;
					width: 0.3rem;
					background-color: $success-color;
					border-radius: 50%;
					display: inline-block;
					margin-left: -0.75rem;
					margin-right: 0.5rem;
					vertical-align: middle;
				}
			}
			.toggle-setting-table {
				text-decoration: underline;
				color: $primary-color;
				cursor: pointer;
			}
		}
		:focus {
			outline: 0;
			outline: none;
		}
		.highlight {
			background: #3fcda6 0% 0% no-repeat padding-box;
			// color: #ffffff;
			// box-shadow: 0 3px 0.375rem rgba(0,0,0,0.2);
		}
		.log-restore {
			text-align: center;
			text-decoration: underline;
			font: 1.0rem/1.5rem;
			letter-spacing: 0;
			color: #0076c0;
			margin-top: 0.5rem;
			opacity: 1 !important;
			.icon {
				color: black;
				margin-right: 0.25rem;
				font: 1.2rem/1.5rem;
			}
		}
	}
	.decline-log-done {
		opacity: 1;
	}

	.redo-undo-bar {
		width: 100%;
		height: 4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.left {
			margin-left: 1.5rem;
			font-weight: bold;
			cursor: pointer;
			.icon {
				margin-right: 0.5rem;
			}
		}
		.redo-undo-icon {
			margin-right: 1.5rem;
			display: flex;
			cursor: not-allowed;
			.circle {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 2rem;
				height: 2rem;
				color: #ddd;
				border: 0.125rem solid #ddd;
				border-radius: 1rem;
				margin-left: 1rem;
				&.active {
					cursor: pointer;
					color: black;
					border: 0.5px solid black;
				}
			}
		}
	}

	.decline-tab-selector {
		display: flex;
		width: 100%;
		height: 100%;

		.tab-option {
			cursor: pointer;
			outline: 0 !important;
			box-shadow: none !important;
			display: flex;
			flex: 1;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.icon {
				width: 22px !important;
				height: 22px;
				position: relative;
				color: #4b4d53;
			}

			.text {
				user-select: none;
				font-size: 1rem;
				font-weight: normal;
			}
			.selected {
				color: white;
				font-weight: bold;
			}
			&.selected {
				background: $secondary-color;
			}
			&.disabled {
				background: gray;
				cursor: not-allowed;
			}
		}

		
	}
	.decline-autopilot {
		border: 0.125rem solid #f4f4f4;
		border-radius: 0.125rem;
		padding: 1rem;
		font: 1.0rem/1.5rem;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #0B0D11;
		.left {
			margin-left: 0.25rem;
			.icon {
				width: 1.2rem;
				height: 1.2rem;
				margin-right: 0.25rem;
			}
		}
		.run {
			color: #3FCDA6;
			text-decoration: underline;
			margin-right: 0.15rem;
		}
	}
}

.filter-header {
	display: flex;
	align-items: center;
	margin: 1rem;
	.clear-filter {
		font-size: 0.8rem;
		border-radius: 1rem;
		border: 1px solid black;
		padding: 0.3rem 1rem;
		cursor: pointer;
	}
	.search-well {
		flex: 1;
		margin-right: 1rem;
	}
}

.decline-accordion {
	border: 1px solid #f4f4f4;
	border-radius: 0.125rem;
	direction: rtl;
	user-select: none;
	.item {
		border-top: 0.125rem solid #f4f4f4;
		background-color: white;
		direction: ltr;
		.header {
			background-color: white;
			display: flex;
			height: 50px;
			width: 100%;
			&:hover {
				cursor: pointer;
			}
			.button {
				display: flex;
				width: 100%;
				.inner-accordion-header {
					width: 100%;
					height: 100%;
					align-self: center;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-items: center;

					.icon {
						margin-left: 1rem;
						margin-right: 1rem;
					}
					.name {
						font-size: 1rem;
						font-weight: normal;
						margin-right: auto;
					}
					.icon-right {
						margin-right: 1rem;
					}
				}
				.flag {
					color: $error-color !important;
				}

				.text-accordion-selected {
					color: green;
				}
				.header-accordion-selected {
					background: #ddd;
				}
			}
		}
		.panel {
			padding: 1.25rem;
			animation: fadein 0.35s ease-in;
		}
	}
}

.dca-charts-panel {
	display: flex;
	width: 100%;
	flex-direction: column;
	min-height: 30rem;
	.dca-charts-header {
		font-size: 0.9rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 0.5rem;
		margin-top: 1rem;
		.well-count {
			color: gray;
			font-size: 1.1rem;
			font-weight: bold;
			margin-right: 1rem;
			.filtered {
				color: $primary-color;
			}
		}
		.curve-img {
			width: 2rem;
		}
	}
	.sort {
		display: flex;
		align-self: flex-start;
		align-items: center;
		.sort-icon {
			font-size: 1.3rem;
			margin-left: 0.5rem;
			cursor: pointer;
		}
	}
	.chart-select-option{
		margin-left: 0.5rem;
		width: 10rem;
	}
}

.well-by-well-charts {
	margin-top: 1rem;
	display: grid;
	padding: 0.1rem;
	grid-template-columns: repeat(auto-fit, 28rem);
	column-gap: 1rem;
	row-gap: 1rem;
	justify-content: space-evenly;
	align-items: center;
	.well-chart {
		display: flex;
		flex-direction: column;
		padding: 0.5rem;
		width: 100%;
		height: 18rem;
		// &.current-run {
		// 	border: 0.125rem dashed $success-color;
		// }
		&.well-to-qc {
			border: 0.125rem solid $primary-color;
		}
		.well-chart-header {
			padding: 0.5rem 1rem 0 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			.well-name {
				font-size: 1rem;
				font-weight: bold;
			}
			.run-info {
				margin-left: 0.5rem;
				font-size: 0.8rem;
				font-style: italic;
				cursor: pointer;
				color: $primary-color;
				text-decoration: underline;
				&.selected {
					color: #3fcda6;
				}
			}
			.view-details-icon {
				margin-left: auto;
				font-size: 1rem;
				color: $primary-color;
				cursor: pointer;
			}
			.status-icon {
				margin-left: 0.8rem;
				cursor: pointer;
			}
		}
		.plot-well-dca {
			width: 100%;
			height: 100%;
		}
		.no-production {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.2rem;
			color: gray;
		}
	}
}

.well-info-static {
	position: relative;
	width: 28rem;
	height: 25rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	.name {
		padding-top: 0.5rem;
		text-align: center;
		font-size: 1.2rem;
		font-weight: bold;
	}
	.well-chart-static {
		width: 96%;
		height: 100%;
		.plot-well-dca-static {
			width: 100%;
			height: 100%;
		}
	}
	.table-well-static {
		margin-top: 0.5rem;
		width: 98%;
		th,td{
			font-size: 0.7rem;
			white-space: nowrap;
		}
	}
}

.toggle-show-filter {
	margin-right: 1rem;
	font-size: 0.8rem;
	border-radius: 1rem;
	border: 1px solid black;
	padding: 0.3rem 1rem;
	cursor: pointer;
}