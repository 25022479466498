.typecurve-group-panel {
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
	border-radius: 1rem;
	background: white;
	display: flex;
	padding: 2rem;
	flex-direction: column;
	align-items: stretch;
	margin: 1rem 18%;
	position: relative;
	&.regroup-panel {
		margin: 0;
		.group-info {
			.info-icon {
				margin-left: auto;
				margin-right: 0.1rem;
			}
		}
		.regroup-warning-icon{
			color: $warning-color;
			margin-right: 0.5rem;
		}
	}
	.group-prompt {
		display: flex;
		align-items: center;
		color: gray;
		margin-bottom: 1.5rem;
	}
	.group-checkbox {
		display: flex;
	}
	.group-info {
		display: flex;
		align-items: center;
		flex: 1;
		.info-icon {
			margin-left: auto;
			margin-right: 1rem;
			color: gray;
		}
	}
	.action-bar {
		align-self: center;
		margin-top: 1rem;
		display: flex;
		align-items: center;
		.btn-remove-group {
			margin-right: 1rem;
			color: $primary-color;
			cursor: pointer;
		}
	}
}

.within-group-panel {
	display: flex;
	align-items: flex-start;
	.typecurve-qc-chart {
		flex: 1;
		margin-right: 1rem;
		padding: 0.5rem;
		height: 28rem;
		.well-qc-chart {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.plot-typecurve-qc {
			width: 100%;
			height: 100%;
		}
	}
	.clustering {
		width: 30rem;
		display: flex;
		flex-direction: column;
		.clustering-settings {
			display: flex;
			flex-direction: column;
			padding: 1rem 2rem;
			.header {
				font-weight: bold;
				margin-bottom: 1rem;
			}
			.action-bar {
				margin-top: 1rem;
				align-self: center;
				display: flex;
				align-items: center;
				.btn-remove-cluster {
					margin-right: 1rem;
					color: $primary-color;
					cursor: pointer;
				}	
			}
			
		}
		.clusters {
			display: flex;
			flex-direction: column;
			margin-top: 1rem;
			padding: 1rem;
			.header {
				display: flex;
				.number-wells {
					margin-left: 1rem;
					color: gray;
				}
				.cluster-view-all {
					margin-left:auto;
					color: $primary-color;
					cursor: pointer;
				}
			}
			.view-cluster {
				color: $primary-color;
				cursor: pointer;
			}
		}
	}
}

.case-typecurve-main {
	background: inherit;
	margin: 0.5rem 5%;
	display: flex;
	flex-direction: column;
	position: relative;
	.map-info {
		display: flex;
		margin-bottom: 1.5rem;
		.google-map-container {
			flex: 1;
			height: 25rem;
		}
	}
	.well-info-static {
		margin-left: 1rem;
		width: 30rem;
	}
	.well-marker {
		position: absolute;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		.marker {
			cursor: pointer;
		}
	}

	.well-marker-tooltip {
		min-width: 10rem;
		text-align: center;
	}
	.group-header {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		.group-chip {
			display: flex;
			align-items: center;
			border-radius: 1000px;
			padding: 0.1rem 0.5rem;
			font-weight: bold;
			color: white;
			margin-right: 1rem;
		}
		.group-header-prompt {
			color: gray;
		}
		.regroup-wells {
			margin-left: auto;
			margin-right: 2rem;
			.menu-icon {
				margin-right: 0.5rem;
				color: $primary-color;
				cursor: pointer;
			}
			.regroup-panel {
				width: 20rem;
				padding: 1rem;
			}
		}
		.view-map-switch {
			margin-right: 0.3rem;
		}
	}
	.group-cards {
		margin-top: 1rem;
		display: grid;
		padding: 0.1rem;
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		column-gap: 1rem;
		row-gap: 1rem;
		justify-content: space-between;
		.group-card {
			display: flex;
			flex-direction: column;
			max-width: 30rem;
			padding-bottom: 1rem;
			max-height: 25rem;
			overflow: auto;
			&:hover {
				box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
				transform: translateY(-0.2rem);
				background-color: #e9f4ff;
				cursor: pointer;
			}
			.group-card-header {
				padding: 0.5rem 1rem;
				border-bottom: 1px dashed gray;
				font-size: 1.1rem;
				font-weight: bold;
				display: flex;
				align-items: center;
				.group-highlight {
					color: black;
					margin-left: auto;
					font-size: 0.8rem;
					text-decoration: underline;
					cursor: pointer;
					.map-marker {
						margin-right: 0.5rem;
						display: inline;
					}
				}
			}
			.group-card-subheader {
				margin-top: 1rem;
				padding: 0.1rem 2rem;
				display: flex;
				align-items: center;
			}

			.group-info {
				display:flex;
				margin: 0.5rem 2rem;
				.group-name {
					color: gray;
					margin-right: 1rem;
				}
				.group-value {
					margin-left: auto;
					text-align: right;
				}
			}
		}
	}
}
