@import "../colors.scss";

.files-panel {
	margin-top: 2rem;
	padding-bottom: 1rem;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.header {
		font-size: 1.5rem;
		font-weight: bold;
		text-align: center;
	}
	.files-table {
	  width: 95%;
	}
	.icon {
	  margin-right: 0.5rem;
	  margin-left: 0.5rem;
	  cursor: pointer;
	  font-size: 1.2rem;
	  color:gray;
	}
	.download-icon {
	  cursor: pointer;
	  font-size: 1.2rem;
	  color:gray;
	}
	.file-upload-progressbar {
	  margin-right: 1rem;
	  min-width: 4rem;
	  max-width: 20rem;
	  flex: 1;
	}
  }

.case-initialize-main {
	margin: 1rem calc(50% - 40rem);
	background: inherit;
	display: flex;
	flex-direction: column;
	position: relative;
	
	.header {
		display: flex;
		font-size: 1.5rem;
		align-self: center;
		font-weight: bold;
		margin-bottom: 1rem;
	}

	.subheader {
		display: flex;
		font-size: 1.2rem;
		color: gray;
		align-self: center;
		margin-bottom: 1rem;
	}

	.file-details {
		margin-top: 2rem;
		box-shadow: .1rem .1rem .1rem rgba(0, 0, 0, .2);
		display: flex;
		flex-direction: column;
		padding: 2rem;
		background-color: white;
		.file-details-header {
			font-size: 1.3rem;
			display: flex;
			align-items: center;
			padding: 0 2rem 1rem 2rem;
			justify-content: space-between;
			border-bottom: 0.25rem solid gray;
			.download-icon {
				cursor: pointer;
			}
		}
	}

	.file-selection-panel {
		overflow-x: auto;
	}
	.select-production-column-header {
		margin: 2rem 0;
		font-size: 1.3rem;
		font-weight: bold;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.select-production-column-inputs {
		width: 100%;
		display: flex;
		justify-content: center;
		.select-production-column{
			width: 20rem;
			max-width: 40%;
			margin-right: 2rem;
		}
	}
	.initialize-button {
		margin-top: 1rem;
		display: flex;
		justify-content: center;
	}
}

.search-file-header-bar {
	width: 100%;
	height: 4rem;
	position: relative;
	.search-box {
		position: absolute;
		width: 30rem;
		max-width: 35%;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
	}
	.header-text {
		font-size: 1.3rem;
		font-weight: bold;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.no-file {
	font-size: 1rem;
}