
.with-sidebar{
  @media screen and (min-width: 1000px) {
    margin: 2rem 0 0 2rem;
  }
  background: inherit;
  display: flex;
  min-height: 700px;
  align-items: stretch;
  .sidebar {
      display: flex;
      position: relative;
      flex-direction: column;
      border:solid 0.125rem #E1E1E1;
      border-radius: 0.9375rem;
      background:white;
      width: 25rem;
      margin-bottom: 1rem;
      margin-left: 1rem;
      margin-right: 2rem;
      margin-top: 1rem;
      transition: margin-left 0.65s;
      overflow:auto;
      .sidebar-close {
        position: absolute;
        top:0;
        right:0;
        margin-right: 1rem;
        margin-top: 1rem;
        background-color: #E9F4FF;
      }
      .sidebar-title {
        justify-self: center;
        align-self: center;
        padding: 2rem 0;
        background-color: #E9F4FF;
        width: 100%;
        text-align: center;
        color:#657889;
        font-weight:inherit !important;
      } 
      .sidebar-content {
        .sidebar-button {
          display: block;
          padding:.5rem;
          margin:.5rem; 
          color:#657889;
          &:hover {
            background-color: #657889;
            color:#FFFFFF 
          }
          border-radius: 2rem;;
        }
        .sidebar-icon {
          position: relative;
          background-color: #657889;
          color: #FFFFFF;
          border-radius: 50%;
          padding:.3rem;
          top:0.125rem;
          font-size: 1.7rem !important;
          width: 1.7rem !important;
        }
      }
      .sidebar-text {
        padding-left: .7rem;
        padding-top: .7rem;
        .collab-icon {
          background-color: #ffffff;
          color:#0076C0;
        }
        .collab-button {
            &:hover {
              color:#0076C0;
            }
            border-radius: 2rem;;
            &:active {
              outline:none !important
            }
            &:focus {
                outline:none !important
            }
        }
        .picky__input {
          border: none !important;
          margin-top: .4rem;
          &:active {
            outline:none !important
          }
          &:focus {
              outline:none !important
          }
        }
        .collab-submit {

          text-align: right;
          justify-items: center;
          .btn {
            margin-top: .5rem;
            background-color: #007AF4;
            color:#FFFFFF;
          }
        }
        .edit-description-icon {
          &:hover {
            cursor: pointer;
            color:red;
          }
          &:active {
            outline:none !important
          }
          &:focus {
            outline:none !important
          }
        }
        .no-outline {
          border: solid #657889 1px;
          margin-bottom: .5rem;
          &:active {
            outline:none !important
          }
          &:focus {
            outline:none !important
          }
        }
      }
      .collabs {
        padding-top: .8rem;
        list-style: none;
        li {
          padding: .8rem .8rem 0 0;
          margin-left: -2rem;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .collab-remove-icon {
          &:hover {
            color:red
          }
        }

      }
  }
  .main-content {
      flex: 1;
      flex-direction: column;
      background:inherit;
      overflow: hidden;
      margin-left: 0.5rem;
      margin-right: 2rem;
      margin-bottom: 1rem;
  }

}
