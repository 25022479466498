.qri-admin-page {
  background-color: white;
  .qri-admin-header {
    background-color: inherit;
    width: 100%;
    height: 90px;
    position: relative;
    .right {
      color: $primary-text-color;
      margin-right: 2rem;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      .icon-download {
        font-size: 1.5rem;
        margin-left: 1rem;
        cursor: pointer;
      }
    }
    .header {
      font-size: 1.5rem;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .qri-admin-container {
    margin-top: 0;
    .table-preprocessing {
      margin-top: 0 !important;
      .delete {
        color: red;
        &:hover {
          color: lighten(red, 25%);
          text-decoration: underline;
        }
      }
      .blue-hyperlink {
        color: #0000ee;
        &:hover {
          color: lighten(#0000ee, 25%);
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .table-wrap {
        min-height: 30rem;
        width: 98%;
        margin: 0 1%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.create-user-form {
  width: 100%;
  .create-user-textbox {
    width: 100%;
    padding: 0.2rem 1rem;
  }
}
