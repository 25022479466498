@import "../colors.scss";

.project-container {
  display: flex;
  max-width: 100%;
  .list-container-default {
    .project-card {
      margin-bottom: 30px;
    }
  }
  .project-container-right {
    flex: 1;
    .pagination {
      padding-right: 4rem;
    }
  }
  .workspace-pagination-padding {
    .pagination {
      padding-right: 10rem;
    }
  }
}

.main-grid {
  display: grid;
  grid-template-columns: 1fr;
  .grid-item2 {
    margin-top: 10rem;
    .workspace-page-img {
      width: 275px;
    }
  }
  .projects {
    .sidebar {
      display: flex;
      position: relative;
      flex-direction: column;
      background: white;
      height: 100%;

      .sidebar-close {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 1rem;
        margin-top: 1rem;
        background-color: #e9f4ff;
      }
      .sidebar-title {
        justify-self: center;
        align-self: center;
        padding: 2rem 0;
        background-color: #e9f4ff;
        width: 100%;
        text-align: center;
        color: #657889;
        font-weight: inherit !important;
      }
      .sidebar-content {
        padding: 0 20px 20px 0px;
        .sidebar-button {
          display: block;
          padding: 0.5rem;
          margin: 0.5rem;
          color: #657889;
          &:hover {
            background-color: #657889;
            color: #ffffff;
          }
          border-radius: 2rem;
        }
        .sidebar-icon {
          position: relative;
          background-color: #657889;
          color: #ffffff;
          border-radius: 50%;
          padding: 0.3rem;
          top: 2px;
          font-size: 1.7rem !important;
          width: 1.7rem !important;
        }
      }
      .sidebar-text {
        padding: 0 20px 20px 20px;

        .collab-icon {
          background-color: #ffffff;
          color: #0076c0;
        }
        .collab-button {
          margin-bottom: 20px;
          &:hover {
            color: #0076c0;
          }
          &:active {
            outline: none !important;
          }
          &:focus {
            outline: none !important;
          }
        }

        .picky__input {
          border: none !important;
          margin-top: 0.4rem;
          &:active {
            outline: none !important;
          }
          &:focus {
            outline: none !important;
          }
        }
        .collab-submit {
          text-align: right;
          justify-items: center;
          margin-top: 10px;
          .primary-button {
            margin-top: 0.5rem;
            background-color: #007af4;
            color: #ffffff;
            margin-right: 16px;
            &:hover {
              background-color: lighten(#007af4, 5%);
            }
          }
        }
        .edit-description-icon {
          &:hover {
            cursor: pointer;
            color: red;
          }
          &:active {
            outline: none !important;
          }
          &:focus {
            outline: none !important;
          }
        }
        .no-outline {
          width: 100%;
          margin-bottom: 0.5rem;
          &:active {
            outline: none !important;
          }
          &:focus {
            outline: none !important;
          }
        }
      }
      .collabs {
        list-style: none;
        padding-bottom: 32px;
        padding-top: 4px;
        li {
          padding: 6px 16px 0 16px;
          margin-left: -2rem;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .collab-remove-icon {
          &:hover {
            color: red;
          }
        }
      }
    }
    .main-content {
      flex: 1;
      flex-direction: column;
      background: inherit;
      overflow: hidden;
      margin: 32px 0 0 32px;
    }
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      @media screen and (min-width: 1200px) {
        width: 25% !important;
      }
      border: 3px solid #efefef;
      top: 0;
      .bm-menu {
        padding: 0;
        .sidebar-text {
          padding-bottom: 6rem;
          .picky__dropdown {
            &:last-child {
              padding-bottom: 10rem;
            }
          }
        }
      }
    }
  }
  .bold {
    font-weight: bold;
  }
}

.project-card {
  border: 0.1rem solid #dadadb;
  background-color: white;
  border-radius: 1rem;
  color: #363636;
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  box-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2);
  width: 16rem;
  &:hover {
    box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
    background-color: #e9f4ff;
    cursor: pointer;
  }
}


#inputSearch {
  font-weight: bold;
}
.project-selector {
  margin-top: 2rem;
}

.project-card-top {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: 0 0 0 0 !important;
  height: 100px;
  border-bottom: 0.1rem solid #dadadb;
  font-weight: 500 !important;
  .workspace-card-icon {
    width: 50px;
    height: 60px;
    padding-bottom: 20px;
  }
  .project-card-icon {
    width: 37px;
    height: 37px;
    margin-bottom: 20px;
  }
  .enter-icon-container {
    display: inline-block;
    align-self: flex-end;
    margin: 0;
    padding: 10px 10px 15px 15px;
    color: $dark-grey;
    z-index: 0;
    &:hover {
      color: $bright-blue;
      transform: scale(1.25);
    }
  }

  .reset-margin {
    margin: -40px 1rem !important;
  }
  &__text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 14rem;
    margin: 0 0rem 1rem 0 !important;
    font-weight: 600;
  }
  .card-desc {
    padding-top: 1rem;
    text-align: right;
    color: #0077ff;
  }
}

.project-card-bottom {
  height: 7rem;
  padding: 1.5rem 0rem 0rem 0rem;
  .card-desc {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

.project-card-bottom-buttons {
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 0rem 1rem 0rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.project-card-bottom-row {
  display: inline;
  text-align: center;
  border-radius: 2rem;
  background-color: rgba(120, 179, 247, 0.5);
  padding: 0.2rem 0.6rem;
  margin: 0.5rem !important;
  color: #0077ff;
  vertical-align: middle;
}

.addcard {
  width: 16rem;
  box-shadow: none;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a7a9ae;
  margin-right: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  background: #fafafa 0% 0% no-repeat padding-box;
}

.project-headers {
  // font-family: "Proxima Nova", "Semibold";
  color: #0076c0;
  font-weight: bold;
  margin: 1rem 0;
}

.hidden {
  display: none !important;
}

.view-all {
  width: 100%;
  text-align: center;
  color: #007af4;
}

.projects-page-empty-workspace {
  display: flex;
  justify-content: space-around;
  width: 100%;
  .greeting-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 6rem;
    .greeting {
      color: #2c7ebb;
    }
    .first-workspace {
      font-size: 1.5rem;
      letter-spacing: 0;
      color: #474747;
    }
  }
}
