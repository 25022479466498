.case-playground-main {
	margin-top: 1rem;
	display: flex;
	align-items: stretch;

	.playground-nav {
		background-color: white;
		padding: 2rem 0;
		width: 22rem;
		border-radius: 0.5rem;
		display: flex;
		flex-direction: column;
		margin: 0 1rem 0 2rem;
		box-shadow: .1rem .1rem .1rem rgba(0, 0, 0, .2);
		.playground-filter {
			.back {
				margin-left: 1rem;
				margin-bottom: 2rem;
				cursor: pointer;
				color: $primary-color;
			}
		}

		.nav-item {
			cursor: pointer;
			border-left: 0.3rem solid white;
			border-radius: 0.3rem;
			display: flex;
			align-items: center;
			width: 90%;
			padding: 0.6rem;
			margin: 0 5%;
			color: $secondary-color;
			&.selected {
				border-left-color: $primary-color;
				background-color: rgba($primary-color, 0.06);
			}
			.icon {
				width: 2rem;
				height: 2rem;
				margin-right: 1rem;
			}
		}
	}
}

.orange-text {
	color: #ff5e00;
	font-size: 1.5rem;
}

.well-dca-panel {
	flex: 1;
	flex-direction: column;
	margin-right: 2rem;
	margin-left: 1rem;
	position: relative;
}

.wellgroup-panel {
	flex: 1;
	flex-direction: column;
	margin-right: 1rem;
	position: relative;
	.generate-decline {
		margin-left: auto;
		.generate-decline-panel {
			width: 20rem;
			padding: 1rem;
		}
	}
	.wellgroup-charts {
		margin-top: 1rem;
		display: grid;
		padding: 0.1rem;
		grid-template-columns: repeat(auto-fit, 30rem);
		column-gap: 1rem;
		row-gap: 1rem;
		justify-content: space-evenly;
		align-items: center;
		.group-info {
			display: flex;
			margin: 0 2rem;
			align-self: center;
			.group-name {
				color: gray;
				margin-right: 1rem;
			}
			.group-value {
				margin-left: auto;
				text-align: right;
			}
		}
		.wellgroup-chart {
			display: flex;
			flex-direction: column;
			padding: 0.5rem;
			width: 100%;
			.wellgroup-decline-plot {
				width: 100%;
				height: 18rem;
			}
		}
	}
}

.publish-panel {
	display: flex;
	justify-content: center;
	overflow: auto;
	flex: 1;
	.publish-panel-card {
		padding: 5rem 2rem;
		display: flex;
		align-items: center;
		border-radius: 0.5rem;
		background-color: white;
		max-width: 60rem;
		flex: 1;
		box-shadow: 0px 0.3rem 0.5rem #00000029;
		&.published {
			flex-direction: column;
			.published-header {
				font-size: 1.8rem;
				color: #3fcda6;
				margin-bottom: 1.5rem;
			}
			.published-subheader {
				font-size: 1.2rem;
				color: #605b5b;
				margin-bottom: 2rem;
			}
			.img-cases-header {
				width: 40rem;
				margin-bottom: 2rem;
			}
			.notice {
				font-style: italic;
				margin-bottom: 2rem;
				.notice-icon {
					color: gray;
					margin-right: 0.5rem;
				}
			}
		}
		.left {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.publish-header {
				font-weight: bold;
				font-size: 1.8rem;
				margin-bottom: 2rem;
			}
			.publish-subheader {
				font-weight: bold;
				font-size: 1.3rem;
				margin-bottom: 1.5rem;
			}
			.prompt {
				margin-bottom: 1.5rem;
			}
			.publish-button {
				align-self: center;
			}
		}
		.right {
			width: 30rem;
		}
	}
}

.export-data-panel {
	flex: 1;
	margin-right: 2rem;
	padding: 4rem 0;
	font-size: 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.export-cards {
		margin-top: 3rem;
		display: flex;
		justify-content: space-evenly;
		width: 100%;
		.export-card {
			overflow: hidden;
			width: 18rem;
			box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, .1);
			display: flex;
			flex-direction: column;
			.export-card-header {
				padding: 2rem 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 1.2rem;
				color: gray;
				background-color: rgba($primary-color, 0.15);
				.icon {
					font-size: 4rem;
					margin-bottom: 1rem;
				}
			}
			.export-card-content {
				font-size: 0.9rem;
				padding: 1rem;
				color: #555;
				display: flex;
				flex-direction: column;
				align-items: center;
				flex: 1;
				.prompt {
					margin-bottom: 1.5rem;
				}
				.download-link {
					font-size: 1.1rem;
					margin-top: auto;
					color: $primary-color;
					cursor: pointer;
					.download-icon {
						margin-left: 1rem;
					}
					&.disabled {
						color: gray;
						cursor: not-allowed;
					}
				}
			}
		}
	}
}

.export-panel {
	// obselete
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-right: 1rem;
	margin: 0 calc(50% - 30rem);
	justify-content: center;
	position: relative;
	.export-setting {
		padding: 2rem;
	}
	.export-button {
		align-self: center;
	}
}

.report-panel {
	flex: 1;
	flex-direction: column;
	margin-right: 1rem;
	.playground-content-header {
		.report-popover-header {
			display: flex;
			font-size: 1.3rem;
			color: gray;
			white-space: nowrap;
			align-items: center;
			margin-bottom: 1rem;
			.add-report-image {
				width: 4rem;
				height: 4rem;
				margin-right: 1rem;
			}
		}
		.prompt {
			text-align: center;
			color: gray;
			font-weight: bold;
			margin-bottom: 0.7rem;
		}
	}
}

.report-landing {
	padding: 2rem 2rem;
	font-size: 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 4rem;
	font-weight: 500;
	.report-image {
		width: 16rem;
		margin-top: 2rem;
	}
}

.playground-busy-panel {
	width: 100%;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
}

.playground-content-header {
	display: flex;
	padding: 1rem 2rem;
	width: 100%;
	.left {
		max-width: 50%;
		margin-right: 2rem;
		.header {
			font-size: 1.3rem;
		}
		.description {
			font-size: 1rem;
			color: black;
		}
	}

	.right {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
	}
}

.field-insight-panel {
	flex: 1;
	flex-direction: column;
	margin-right: 1rem;
	position: relative;
	background-color: inherit;
	.field-insight-charts {
		margin-top: 1rem;
		display: grid;
		padding: 0.1rem;
		grid-template-columns: repeat(auto-fit, 32rem);
		column-gap: 1rem;
		row-gap: 1rem;
		justify-content: space-evenly;
		align-items: center;
		.field-insight-chart {
			border-radius: 2px;
			.header {
				color: gray;
				padding: 1.5rem;
				border-bottom: 2px solid #ddd;
				.header-text {
					font-size: 1.3rem;
					font-weight: bold;
					margin-bottom: 0.5rem;
				}
			}
			.chart {
				padding: 1rem 0 1rem 1rem;
			}
		}
		.field-decline-plot {
			width: 100%;
			height: 20rem;
		}
	}
}

.playground-type-curve-view {
	flex: 1;
	margin-left: 1rem !important;
	.case-typecurve-main {
		margin-left: 2rem;
		margin-right: 2rem;
		.case-sub-header-bar {
			margin-top: 0;
		}
	}
}


.welcome-to-playground {
	flex: 1;
	background-color: white;
	display: flex;
	border-radius: 0.3rem !important;
	.left {
		flex: 1;
		display: flex;
		flex-direction:column;
		margin-left: 3rem;
		margin-right: -3rem;
		.header {
			margin-top: 10rem;
			margin-bottom: 1rem;
			font-size: 2.2rem;
			color:#F35A5F;
			font-weight: bold;
		}
		.prompt {
			color: #657889;
			font-size:1.5rem; 
			margin-bottom: 1rem;
		}
		.prompt-text {
			color: gray;
		}
	}
	  .right {
		width: 74vh;
		height: 72vh;
	  }
}