@import "../colors.scss";

.autopilot-panel {
	box-shadow: 2px 2px 6px #0000000F;
	border: 1px solid #C8C8C8;
	background-color: white;
	padding: 2rem;
	font-size: 1.2rem;
	color: $primary-color;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 2rem calc(min(20rem, 20%));
	.autopilot-image {
		margin-top: 3rem;
		height: 10rem;
	}
}