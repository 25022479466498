$progress-height: 0.125rem;
$progress-padding: $progress-height * 2;

.progresss-bar-wrapper {
  width: 100%;
  display: flex;
  .icon {
    color: $error-color;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    transition: color 0.4s ease 0s;
    cursor: pointer;
    &.success-complete {
      color: $success-color;
    }
    &.success-fail {
      color: $error-color;
    }
    &.pause {
      color: $error-color;
    }
    &.resume {
      color: $success-color;
    }
  }
}

.progressbar {
  overflow: hidden;
  flex: 1;
  padding-top: $progress-padding;
  position: relative;
  .bar {
    background: rgba(0,0,0,0.075);
  }
  .progress-icon{
    position: absolute;
    top:50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .progress {
    color: #fff;
    padding: $progress-height;
    transition: width 0.3s ease 0s;
    &.active {
      background: transparent linear-gradient(325deg, #40C1AD 0%, #40C1AD 1%, #44DA9D 100%) 0% 0% no-repeat padding-box;
    }
  }
}

