// file imports
@import "colors.scss";
@import "fonts.scss";
@import "scrollbar.scss";
@import "thirdparty.scss";
@import "./components/forms";
@import "./components/textboxes";
@import "./components/buttons";
@import "./components/modal";
@import "./components/progressbar";
@import "./components/project_card";
@import "./components/messages";
@import "./components/table";
@import "./layout/playground";
@import "./layout/case";
@import "./layout/decline";
@import "./layout/typecurve";
@import "./layout/case_initialize";
@import "./layout/_project_page";
@import "./layout/case_sidebar";
@import "./layout/header";
@import "./layout/product_space";
@import "./layout/setting";
@import "./layout/sidebar";
@import "./layout/visualization";
@import "./layout/prediction_api_page";
@import "./layout/faq";
@import "./layout/main_landing";
@import "./layout/my_account";
@import "./layout/autopilot";
@import "other.scss";
@import "./layout/membership";
@import "./layout/qri_admin_page";
@import "./layout/helpmodal";

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Regular.ttf) format("truetype"); //local('Roboto'),
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Medium.ttf) format("truetype");
  font-style: medium;
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-y: scroll;
}
html {
  font-size: 16px;
}
@media (max-width: 900px) {
  html { font-size: 14px; }
}
@media (max-width: 400px) {
  html { font-size: 12px; }
}
@media (max-width: 300px) {
  html { font-size: 8px; }
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

#app {
  color: $primary-text-color;
  font-size: 12px;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#outer-container {
  margin: 0rem;
  background-color: #f9f9f9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // font-family: "Proxima Nova", "Semibold";
    font-weight: 600;
  }
  div {
    font-family: "Roboto";
  }
}

.required {
  color: red;
}
