button {
	background: none;
	border: 0;
	color: inherit;
	/* cursor: default; */
	font: inherit;
	line-height: normal;
	overflow: visible;
	padding: 0;
	-webkit-appearance: button; /* for input */
	-webkit-user-select: none; /* for button */
	-moz-user-select: none;
	-ms-user-select: none;
	&:active {
        outline:none !important
    }
    &:focus {
        outline:none !important
    }
}

.tiny-button {
	color: #2196f3 !important;
	font-weight: bold;
	background: none;
	border: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
	&:disabled {
		color: $light-grey !important;
		cursor: not-allowed;
	}
	&.download {
		font-size: .8em;
		svg {
			margin-right: .3em;
		}
	}
}

.primary-button {
	background: none;
	font-size: inherit;
	padding: .2rem 1rem;
	box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
	border: none;
	font-weight: 300;
	border-radius: 50px;
	color: white;
	cursor: pointer;
	background-color:$primary-button-color;
	transition: background-color .1s linear;
	user-select: none;

	&:hover {
		background-color: lighten($primary-button-color, 10%);
		transition: background-color .1s linear;
		text-decoration: none;
	}

	&.error {
		background-color:$error-color;
		&:hover {
			background-color: lighten($error-color, 10%);
		}
	}
	
	&:disabled {
		background-color: grey;
		cursor: not-allowed;
	}

	&.lg {
		font-size: 1.5rem;
		padding: 0.5rem 2rem;
	}
}

.secondary-button {
	// margin-bottom: 1em;
	background: none;
	font-size: inherit;
	padding: .5em;
	box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
	border: none;
	font-weight: 300;
	border-radius: 0.25rem;
	color: white;
	cursor: pointer;
	background-color: #525252;
	transition: background-color .1s linear;

	&:hover {
		background-color: lighten(#525252, 10%);
		transition: background-color .1s linear;
		text-decoration: none;
	}

	&:disabled {
		background-color: grey;
		cursor: not-allowed;
	}
}

.tertiary-button {
	// margin-bottom: 1em;
	background: none;
	background-color: transparent;
	font-size: inherit;
	padding: .5em;
	border: 0.125rem solid $bright-blue;
	font-weight: 300;
	border-radius: 0.25rem;
	cursor: pointer;
	box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
	&:disabled {
		border-color: lightgrey;
		cursor: not-allowed;
	}
}

.close-button {
	color: #2196f3;
	background: none;
	border: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.button-group {
	display: flex;
	width: 250px;
	// border: solid 1px;
	border-radius: 0.25rem;
	box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
	// margin-bottom: 1em;
	&.long {
		width: 200px;
	}
	.individual-button-group {
		font-weight: 300;
		flex-grow: 1;
		background: none;
		border: none;
		padding: .5em;
		cursor: pointer;
		transition: background-color .1s linear;
		&.is-selected {
			background-color: #525252;
			color: white;
			transition: background-color .1s linear;
			cursor: default;
			&:first-child {
				border-top-left-radius: 0.25rem;
				border-bottom-left-radius: 0.25rem;
			}
			&:last-child {
				border-top-right-radius: 0.25rem;
				border-bottom-right-radius: 0.25rem;
			}
		}
	}
	:not(:last-child) {
		border-right: 1px solid;
	}
}
