.modal-usage-warning {
	.modal-title {
		width: 100%;
		text-align: center;
	}
	.usage-warning-header {
		color: $warning-color;
	}
	.usage-warning-action-bar {
		margin-top: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		.not-interested {
			color: $primary-color;
			cursor: pointer;
			margin-right: 2rem;
		}
	}
}

.circle-text {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	font-size: 0.8rem;
}

.link-text {
	color: $primary-color;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
		font-weight: bold;
	}
}

.default-card {
	background-color: white;
	border-radius: 1rem;
	box-shadow: 0 0.2rem 0.5rem rgba(0,0,0,.1);
	.default-card-header {
		font-size: 1.3rem;
		font-weight: bold;
		text-align: center;
	}
}

.input-search {
	max-width: 40rem;
}

.busy-overlay {
  top:0px;
  bottom: 0px;
  right: 0px;
  left:0px;
  background: rgba(#ddd, 0.5);
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-tip {
	position: fixed;
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 50%;
	bottom: 3rem;
	right: 3rem;
	background-color: #f8cb04;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor:move;
	animation: pulse-page-tip 2s infinite;
	.logo {
	  cursor: pointer;
	  width: 2.2rem;
	  height: 2.2rem;
	  &:hover {
		transform: scale(1.3);
	  }
	}
	.tip-box {
	  cursor:default;
	  visibility: visible;
	  width: 28rem;
	  height: 19rem;
	  text-align: left;
	  border-radius: 6px;
	  padding: 1rem;
	  position: absolute;
	  z-index: 1000;
	  bottom: 100%;
	  left: 50%;
	  margin-left: -24rem;
	  margin-bottom: 2rem;
	  // overflow: auto;
	  background: #ffffff 0% 0% no-repeat padding-box;
	  box-shadow: 6px 5px 6px #0000000f;
	  border: 1px solid #cbcbcb;
	  opacity: 0.95;
	  transition: opacity 1s;
	  .tip-header {
		display: flex;
		justify-content: space-between;
		.tip-header-text {
		  font: 1.5rem;
		  color: #3d465e;
		  padding: 0.25rem;
		}
		.tip-close-button {
		  width: 1rem;
		  height: 1rem;
		  &:hover {
			transform: scale(1.25);
		  }
		}
	  }
	  .hr {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	  }
	  .pagination {
		display: flex;
		justify-content: space-between;
		font-size: 1rem;
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
		.left {
		  margin-left: 2rem;
		}
		.right {
		  .clickable {
			color: #0077ff;
			padding: 0.5rem;
			text-decoration: underline;
		  }
		  .nonclickable {
			color: #cacdd1;
			padding: 0.5rem;
			text-decoration: underline;
		  }
		}
	  }
	}
	.tip-box::after {
	  content: "";
	  position: absolute;
	  top: 100%;
	  left: 24rem;
	  margin-left: -0.7rem;
	  border-width: 0.7rem;
	  border-style: solid;
	  border-color: #fefefe transparent transparent transparent;
	  transition: 2s;
	}
  }
  .page-tip:hover {
	animation: none;
  }
  
  @keyframes pulse-page-tip {
	0% {
	  // transform: scale(0.95);
	  box-shadow: 0 0 0 10px #f8cb0439;
	}
  
	70% {
	  // transform: scale(1);
	  box-shadow: 0 0 0 10px #00000000;
	}
  
	100% {
	  // transform: scale(0.95);
	  box-shadow: 0 0 0 10px #f8cb0439;
	}
  }

.control-disabled {
	color: grey;
	&:hover {
		cursor: not-allowed;
	}
}

.spin-reverse {
	animation-direction: reverse;
}

.banner {
	position: absolute;
	z-index: 50;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0.3rem 1.5rem 0.3rem 0.5rem;
	color: white;
	background: $success-color;
	max-width: 40rem;
	max-height: 5rem;
	overflow: auto;
	top: 8.5rem;
	right: 0.5rem;
	.icon {
		position: absolute;
		top: 0.5rem;
		right: 0.2rem;
		width: 1rem;
		height: 1rem;
		cursor: pointer;
	}
	&.info {
		background-color: $primary-color;
	}
	&.warning {
		background-color: $warning-color;
	}
	&.error {
		background-color: $error-color;
	}
}

.pagination {
	width: 100%;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	.pagination-inner {
	  display: flex;
	  align-items: center;
	  list-style-type: none;
	  padding-left: 0;
	  border-radius: 0.5rem;
	  margin-bottom: 0;
	  li {
		border-radius: 0;
		background-color: white;
		a {
		  position: relative;
		  float: left;
		  padding: 0.2rem 0.4rem;
		  text-decoration: none;
		  color: #333;
		  border: 1px solid #ddd;
		  margin-left: -1px;
		}
		&.active {
		  background-color: $primary-color;
		  a {
			color: white;
		  }
		}
		&.disabled {
		  a {
			color: lightgray;
			cursor: not-allowed;
		  }
		}
	  }
	}
	.pagination-icon {
	  margin-right: 0.5rem;
	  cursor: pointer;
	  &.disabled {
		cursor: inherit;
		color: gray;
	  }
	}
  }

.test-container {
	width: 100%;
}

.list-container-default {
	width: 100%;
	display: flex;
	align-items: center;
	align-content: space-around;
	flex-wrap: wrap;
	flex: 1;
	div {
		margin: 0 1rem 1rem 1rem;
	}
}

.list-container-grid {
	width: 100%;
	display: grid;
	align-items: center;
	align-content: space-around;
	flex-wrap: wrap;
	div {
		margin: 1rem;
	}
}

.project-container {
	display: flex;
	max-width: 100%;
	.project-container-right {
		flex: 1;
	}
}

.input-with-icon {
	position: relative;
	width: 100%;
	input[type="text"] {
		padding: 0.3rem 2.5rem 0.3rem 0.5rem;
		width: 100%;
	}
	input[type="number"] {
		padding: 0.2rem 1.8rem 0.2rem 0.5rem;
		width: 100%;
	}
	.icon {
		position: absolute;
		top: 50%;
		right: 0.5rem;
		transform: translateY(-50%);
		color: gray;
		cursor: pointer;
	}
}

.status-message {
	display: inline-block;
	border-radius: 1000px;
    padding: 0.1rem 0.5rem;
	font-weight: bold;
	&.with-border {
		border: 0.125rem solid black;
	}
	&.disabled-bg {
		background-color: $disabled-color;
		color: rgba($secondary-color, 0.4);
	}
	&.error-bg {
		background-color: rgba($error-color, 0.4);
		color: $error-color;
	}
	&.warning-bg {
		background-color: rgba($warning-color, 0.4);
		color: $warning-color;
	}
	&.primary-bg {
		background-color: rgba($primary-color, 0.4);
		color: $primary-color;
	}
	&.success-bg {
		background-color: rgba($success-color, 0.4);
		color: $success-color;
	}
}

.my-tooltip {
	z-index: 100;
	max-width: 20rem;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
	.multi-line {
	  color: gray;
	  text-align: left;
	}
}


.popover-wrapper {
	position: relative;
	display: inline-block;
	overflow: visible;
	.popover-content {
		opacity: 0;
		display: none;
		position: absolute;
		cursor: default;
		top: -10px;
		left: 50%;
		transform: translate(-50%, -100%);
		background-color: #f9f9f9;
		color: black;
		width: auto;
		flex-direction: column;
		box-shadow: 0px 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
		&::after {
			display:none;
			position: absolute;
			z-index: -1;
			content: "";
			right: calc(50% - 10px);
			top: 100%;
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color:  #f9f9f9 transparent transparent transparent;
		}
		&.warning {
			border-top: 5px solid $warning-color;
		}
		&.error {
			border-top: 5px solid $error-color;
		}
		&.info {
			border-top: 5px solid $primary-color;
		}
		&.show {
			z-index: 10;
			opacity: 1;
			display: flex;
			&::after{
				display: block;
			}
			&.hide-arrow {
				&::after{
					display: none;
				}
			}
		}
		&.right-aligned {
			left: auto;
			right: 0;
			transform: translate(0, -100%);
			&::after {
				right: 4rem;
			}
			&.popover-below {
				top: 100%;
				transform: translate(0, 10px);
				&::after {
					top: -0.9375rem;
					border-width: 0 10px 10px 10px;
					border-color: transparent transparent #bfbfbf transparent;
				}
			}
		}
		.message {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-align: left;
			min-width: 20rem;
			margin: 1rem;
			.icon {
				color: $warning-color;
				margin-right: 1rem;
			}
		}
		.action-bar {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.action {
				font-weight: bold;
				margin: 0 0.5rem 0 1rem;
				cursor: pointer;
				color: $primary-color;
			}
		}
	}
	&.hoverable {
		&:hover{
			.popover-content {
				z-index: 10;
				opacity: 1;
				display: flex;
				&::after{
					display: block;
				}
			}
		}
	}
}

.img-crossfade {
	position: relative;
	width: 8rem;
	height: 8rem;
	img {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: white;
	}
	.top {
		animation-name: crossfade;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-duration: 0.8s;
		animation-direction: alternate;
	}
}

.file-upload {
	background-color: rgba($primary-color, 0.05);
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	border-width: 0.125rem;
	border-radius: 0.125rem;
	border-color: #ddd;
	border-style: dashed;
	outline: none;
	padding: 1rem;
	width: 100%;
	&.success {
		border-color: $success-color;
	}
	&.error {
		border-color: $error-color;
	}

	.uploading-text {
		margin-left: auto;
		margin-right: 2rem;
		color: $primary-color;
	}

	.file-upload-error-icon {
		color: $error-color;
		margin-right: 0.3rem;
	}

	.file-upload-error-text {
		text-decoration: underline;
		cursor: pointer;
		color: $error-color;
		.file-upload-error-popover {
			padding: 1rem;
			min-width: 20rem;
			max-height: 15rem;
			overflow-y: auto;
			background-color: white;
			display: flex;
			flex-direction: column;
			align-items: center;
			.file-upload-error-popover-header {
			display: flex;
			align-items: center;
			color: $error-color;
			margin-bottom: 1rem;
			}
		}
	}
	  
	.file-upload-status-icon {
		margin-right: 1rem;
		width: 2rem;
		height: 2rem;
	}
	.file-upload-progress {
		width: 3rem;
		height: 3rem;
		margin-right: 1rem;
		.CircularProgressbar-text {
			font-size: 1.5rem !important;
		}
	}
	.circle-text {
		border: 2px solid #555;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.file-uploaded-icon {
		color: $success-color;
	}

	.file-info {
		display: flex;
		flex-direction: column;
		margin-right: 1rem;
		.file-name {
			font-weight: bold;
			font-size: 1.2rem;
		}
		.file-type {
			color: gray;
			font-size: 0.8rem;
			.download-template {
				margin-left: 0.5rem;
				text-transform: uppercase;
			}
		}
	}

	.file-upload-utlity-icons {
		color: gray;
		margin-left: auto;
		margin-right: 1rem;
		.icon {
			font-size: 1.2rem;
			margin-left: 0.8rem;
			cursor: pointer;
		}
	}
	.file-upload-zone {
		margin-left: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		padding: 1.25rem;
		.browse {
			color: gray;
			border: 1px solid gray;
			padding: 0.5rem;
			margin-left: 0.5rem;
		}
		.upload-icon {
			color: $primary-color;
			margin-right: 0.5rem;
		}
		&.active {
			background-color: rgba($primary-color, 0.3);
			transition: border .2s ease-in-out;
		}
		&.disabled {
			color: gray;
			cursor: not-allowed;
			.upload-icon {
				color: gray;
			}
		}
	}
	.select-type {
		min-width: 10rem;
		margin-left: 0.5rem;
	}
}

.regular-checkbox-span {
	display: flex;
	align-items: flex-start;
	margin-bottom: 0.2rem;
	cursor: pointer;
	label {
		margin: 0;
	}

	.regular-checkbox {
		cursor: pointer;
		display: none;
		margin-right: 0.5rem;
	}
	
	.regular-checkbox + label {
		background-color: #fafafa;
		border: 1px solid #cacece;
		box-shadow: 0 1px 0.125rem rgba(0,0,0,0.05), inset 0px -0.9375rem 10px -12px rgba(0,0,0,0.05);
		padding: 0.5625rem;
		border-radius: 3px;
		display: flex;
		align-items: center;
		position: relative;
	}
	
	.regular-checkbox + label:active, .regular-checkbox:checked + label:active {
		box-shadow: 0 1px 0.125rem rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
	}
	
	.regular-checkbox:checked + label {
		background-color: #e9ecee;
		border: 1px solid #adb8c0;
		box-shadow: 0 1px 0.125rem rgba(0,0,0,0.05), inset 0px -0.9375rem 10px -12px rgba(0,0,0,0.05), inset 0.9375rem 10px -12px rgba(255,255,255,0.1);
		color: #99a1a7;
	}
	
	.regular-checkbox:checked + label:after {
		content: '\2714';
		font-size: 0.875rem;
		position: absolute;
		top: 0px;
		left: 3px;
		color: $success-color;
	}
	
	.big-checkbox + label {
		padding: 18px;
	}
	
	.big-checkbox:checked + label:after {
		font-size: 28px;
		left: 0.375rem;
	}

	.regular-checkbox-text {
		white-space: nowrap;
		color: gray;
	}
}

.close-icon {
	position: absolute;
	top:0;
	right:0;
	margin-right: 0.5rem;
	margin-top: 0.5rem;
	cursor: pointer;
}

@keyframes crossfade {
	0% {
	  opacity: 1;
	}
	50% {
	  opacity: 1;
	}
	51% {
	  opacity: 0;
	}
	100% {
	  opacity: 0;
	}
  }