.help-modal-container {
	&.modal-dialog {
		max-width: 75rem !important;
		width: 80% !important;
		margin: 0.5rem auto;
	}
	.modal-body {
		padding: 0;
		max-height: 98vh;
	}
	.help-img-container {
	  width: 100%;
	  .help-pagi {
		padding: 0.5rem 1rem 1rem 1rem;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.pagi-container {
		  align-items: center;
		  border-radius: 50%;
		  background-color: grey;
		  height: 2rem;
		  width: 2rem;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  &:hover {
			background-color: lighten(grey, 15%);
			cursor: pointer;
		  }
		}
		.empty-div {
			height: 2rem;
		  	width: 2rem;
		}
	  }
	  .help-img {
		width: 100%;
	  }
	}
  }
  