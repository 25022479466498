.api-page-container {
    display:grid;
    grid-template-columns: 1fr .175fr;
    justify-items: center;
    padding:0 2rem;
    .api-calls {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:100%;
        margin-left:2rem;
        text-align: center;
        padding:rem 1rem;
        .api-calls-box {
            box-shadow: 0 3px 0.375rem rgba(0,0,0, .2);
            height:200px;
            border-radius: 1px;
            transition: .3s;
            border-top: solid 5px;
            margin:1.5rem 0;
            .calendar-calls {
                padding-top: 1.5rem;
                font-size: 1.5rem;
            }
            .calls-number {
                font-size: 3rem;
            }
            &.first{
                border-color: #00CAFF;
            }
            &.second{
                border-color: #5A51DD;
            }
            &.third{
                border-color: #F39B12;
            }
        }
    }
}



