.parent-grid {
  display: flex;
  padding: 2rem 4rem;
  width: 100%;
  align-items: flex-start;
  .main-page-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    .welcome-video-parent {
      display: flex;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 6px #0000000f;
      border: 2px dashed #01caff;
      border-radius: 0.8rem;
      align-items: center;
      height: 22rem;
      margin-bottom: 2rem;
      .welcome-video-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .welcome-video-right {
        margin: 2rem 1rem;
        flex: 1;
        video {
          width: 100%;
          max-height: 21rem;
        }
      }
    }
    .charts {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .chart-container {
        background-color: white;
        box-shadow: 0px 1px 6px #0000000f;
        border: 1px solid #bfbfbf;
        border-radius: 0.8rem;
        height: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 48%;
        margin-bottom: 2rem;
        .header {
          padding: 1rem;
          font-size: 1.2rem;
          text-align: center;
          border-bottom: 1px solid gray;
          align-self: stretch;
        }
        .chart {
          width: 95%;
          margin: 1rem 0;
          flex: 1;
        }
      }
    }
  }
  .main-page-right {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #0000000f;
    border: 1px solid #bfbfbf;
    border-radius: 0.8rem;
    margin-left: 2rem;
    padding-right: 0.2rem;
    width: 25rem;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 10rem;
    .no-activity {
      margin: 2rem 0;
      font-size: 1.3rem;
      color: gray;
      width: 100%;
      text-align: center;
    }
    .activity-cards {
      display: flex;
      flex-direction: column;
      padding: 0 1.5rem;
      .change-date {
        color: gray;
        text-align: center;
        margin-bottom: 1rem;
      }
      .activity-card {
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        .right {
          margin-left: 1rem;
          flex: 1;
          display: flex;
          flex-direction: column;
          .header {
            display: flex;
            margin-bottom: 0.5rem;
            .text {
              font-weight: bold;
            }
            .time {
              margin-left: auto;
              color: gray;
            }
          }
          .description {
            .activity-link {
              display: inline;
              color: $primary-color;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
    .activity-tab-selector {
      padding: 1.5rem;
      display: flex;
      .tab-option {
        margin-right: 1.5rem;
        cursor: pointer;
        outline: 0;
        box-shadow: none;
        color: gray;
        .text {
          font-size: 1.1rem;
        }
        &.selected {
          color: #01caff;
          position: relative;
          padding-bottom: 0.5rem;
          .text {
            &::after {
              content: "";
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 1px;
              border-width: 0 0 2px;
              border-style: solid;
            }
          }
        }
      }
    }
  }
  .light-blue-highlight {
    text-align: center;
    font: Medium 24px/40px Roboto;
    letter-spacing: 0;
    color: #01caff;
    margin-bottom: 2rem;
  }
  .prompt {
    color: gray;
  }
  .prompt-upgrade {
    margin-top: 1rem;
    color: gray;
    display: flex;
    align-items: center;
    .contact-us {
      color: $primary-color;
      margin-right: 0.4rem;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .tip-icon {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      bottom: 3rem;
      right: 3rem;
      background-color: #f8cb04;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      .tip-logo {
        width: 1rem;
        height: 1rem;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
.link {
  cursor: pointer;
  color: #3c86ff;
  text-decoration: underline;
  &:hover {
    color: darken(#3c86ff, 25%);
  }
}
