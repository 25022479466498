.my-account-container {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  opacity: 1;
  margin: 4rem 0;
  .account-header {
    width: 100%;
    height: 5rem;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    font-size: 1.5rem;
    color: #605b5b;
  }
  .account-grid {
    display: grid;
    grid-template-columns: 20% 60% 20%;
    width: 100%;
    grid-template-rows: 5rem 5rem;
    justify-items: center;
    .details-row {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      &:nth-child(-n + 3) {
        border-bottom: 1px solid #dfdfdf;
      }

      &.column1 {
        color: #797c80;
        padding-left: 2rem;
      }
      &.column2 {
        align-self: center;
        .alert {
          margin-bottom: 0;
        }
      }
      &.column3 {
        justify-content: flex-end;
        padding-right: 4rem;
        .account-edit {
          &:hover {
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.subscription-container {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  opacity: 1;
  margin: 4rem 0;
  margin-bottom: 5rem;
  .subscription-header {
    width: 100%;
    height: 5rem;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 4rem;
    font-size: 1.5rem;
    color: #605b5b;
    justify-content: space-between;
    .member-plan {
      letter-spacing: 0px;
      color: #0076c0;
      display: flex;
      align-items: center;
      font-size: 1rem;
      .star {
        margin-right: 6px;
      }
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .subscription-grid {
    display: grid;
    grid-template-columns: 20% 40% 20% 20%;
    width: 100%;
    grid-template-rows: 5rem 5rem 5rem;
    justify-items: center;
    .details-row {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      &:nth-child(-n + 9) {
        border-bottom: 1px solid #dfdfdf;
      }

      &.column1 {
        color: #797c80;
        padding-left: 2rem;
      }
      &.column3 {
        color: #797c80;
      }
      &.column4 {
        color: #797c80;
      }
    }
  }
}
