.page-wrap {
  // Used for vertical & horizontal alignment of child
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

form {
  background: white;
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 1em;
  h1 {
    font-size: 1.6em;
    font-weight: 300;
    margin: 0 0 1em 0;
    text-align: center;
  }
  h2 {
    font-size: 1.3em;
    font-weight: 300;
    margin: 0 0 1em 0;
  }
  .submit-button {
    margin-bottom: 1em;
    i, svg { margin-left: 1em; }
  }
  input {
    margin-bottom: 1em;
  }
  input, label {
    display: block;
  }
  // margin: 18%;
  // max-width: 300px;
}

.modal-body {
  overflow: auto;
  max-height: 80vh;
}

.login {
  width: 99.98%;
  height: 99.98%;
  display: flex;
  align-items: center;
  background-color: white;
  .left {
    flex: 1;
    display: flex;
    flex-direction:column;
    margin-left: 3rem;
    .login-product-logo {
      height: 5rem;
      margin-bottom: 2rem;
      align-self: flex-start;
    }
    .login-product-header {
      text-align: left;
      font: Bold 32px/39px Proxima Nova;
      letter-spacing: 3.21px;
      color: #000A47;
      opacity: 1;
      margin-bottom: 2rem;
    }
    .login-textbox {
      font-size: 1rem;
      padding: 0.8rem;
      border-radius: 0.25rem;
      border: 1.5px solid gray;
      &:focus {
        border-color: #2684ff;
      }
    }
  }
  .right {
    width: 102vh;
    height: 99vh;
  }
  .footer{
    position: absolute;
    // margin-bottom: 1rem;
    bottom: 2rem;
    left: 4rem;
    font-size: 0.8rem;
    //margin-left: 4rem;
  }
}

.login-form {
  width: 100%;
  height: 100%;
  input {
    margin-bottom: 1.8rem;
  }
  .privacy-notice {
    font-size: 0.9rem;
    display: block;
    align-items: center;
    margin-bottom: 0.5rem;
    .privacy-notice-icon {
      margin-right: 0.5rem;
      font-size: 1rem;
      color: gray;
    }
    .view-privacy {
      margin-left: 0.5rem;
      cursor: pointer;
      color: $primary-color;
      display: inline-block;
    }
  }
  .instructions {
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
    font-size: .875rem;
    margin-top: 0;
  }
  .forgot-password {
    align-self: flex-start;
    font-size: .875rem;
    background: none;
    border: none;
    color: $primary-color;
    margin-top: 1rem;
    cursor: pointer;
  }
  .login-button {
      background-color: #F57467 !important;
      &:hover {
        background-color: rgb(192, 70, 59) !important;
      }
  }
}

// .portfolio-form {
//   padding: 0;
// }

.invalid-login {
  color: black;
  background-color: $error-color;
  padding: .8em;
  margin-bottom: 1em;
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  i, svg {
    margin-right: 1em;
  }
}

.form-notification {
  display: flex;
  align-items: center;
  color: black;
  &.notification-error {
    background-color: $error-color;
  }
  &.notification-warning {
    background-color: $warning-color;
  }
  &.notification-success {
    background-color: $success-color;
  }
  padding: .5em;
  margin-bottom: .3em;
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  svg {
    margin-right: 1em;
  }
}
