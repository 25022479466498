@import "../colors.scss";
$unselectedTabBackground: #ffffff;

$selectedTabBackground: #4b4d53;
$selectedTabTextColor: #ffffff;

$tabOutline: #c9c9c9;

.project-page-content {
	// transition: margin-right 0.65s;
	// &.narrow {
	// 	margin-right: 25%;
	// 	transition: margin-right 0.65s;
	// }
	@media screen and (min-width: 768px) {
		padding: 2rem 3rem;
	}
	.project-title {
		margin: 2rem 0;
		display: grid;
		grid-template-columns: 70% 30%;
		align-items: center;
		.project-collabs {
			display: flex;
			flex-direction: row;
			text-align: right;
			justify-self: right !important;
			align-items: center;
			.avatar {
				width: 2.5rem;
				height: 2.5rem;
				display: flex;
				align-items: center;
				border-radius: 1.25rem;
				background: #4b4d53;
				text-align: center;
				margin: 0 .1rem;
				&__text {
					width: 100%;
					text-align: center;
					font-weight: 400;
					font-size: 1rem;
					pointer-events: none;
					color: #ffffff;
				}
			}
			.avatar-creator {
				width: 2.5rem;
				height: 2.5rem;
				display: flex;
				align-items: center;
				border-radius: 1.25rem;
				background: #0076c0;
				text-align: center;
				margin: 0 .1rem;
				&__text {
					width: 100%;
					text-align: center;
					font-weight: 400;
					font-size: 1rem;
					pointer-events: none;
					color: #ffffff;
				}
			}
			.avatar-icon {
				width: 3rem;
				height: 3rem;
				display: flex;
				align-items: center;
				border-radius: 50%;
				background: #4b4d53;
				text-align: center;
				margin-left: .2rem;
				&:hover {
					background-color: #0076c0;
				}
				&__text {
					width: 100%;
					text-align: center;
					font-weight: 400;
					font-size: 1.5rem;
					pointer-events: none;
					color: #ffffff;
				}
			}
		}
	}
	.no-cases-display {
		padding: 5rem 10rem 5rem 5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.no-cases-title {
			color: #0076c0;
		}
	}
}

.modal-dialog {
	min-height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: auto;
	@media screen and (max-width: 768px) {
		min-height: calc(100vh - 1.25rem);
	}
	@media screen and (min-width: 768px) {
		max-width: 40rem !important;
	}
	.modal-content {
		&.project-share-modal {
			width: 90rem;
			background-color: yellow;
		}
	}
	.new-case-footer {
		text-align: right;

		&__button {
			background: #3fcda6;
			width: 12rem;
			height: 2.5rem;
			border-radius: 2rem;
			color: #ffffff;
			margin-bottom: 1rem;
			margin-right: 1rem;
			line-height: 1rem;
			font-weight: 500;
			&:hover {
				background-color: rgb(11, 122, 106);
			}
		}
	}

	.project-collab-modal {
		display: grid !important;
		grid-template-columns: 35% 65%;
		height: 26rem;
		font-size: 1.2rem;
		font-weight: 400;
		.project-collab-content {
			display: flex;
			&__left {
				padding: 1rem 0 0 1rem;
				background-color: #f7f7f7;
				.project-owner {
					padding-top: .4rem;
					font-size: 1rem;
					padding-top: .4rem;
					padding-left: 1rem !important;
					line-height: 1.5rem;
					padding-bottom: 1rem;
				}
				.collabs {
					display: grid;
					grid-template-columns: 100%;
					list-style-type: none !important;
					padding-left: 1rem !important;
					font-size: 1rem;
					padding-top: .4rem;
					line-height: 1.5rem;
					.collab-remove-icon {
						padding-left: .8rem;
					}
				}
				.share-qri-warning {
					text-align: center;
					font-size: .8rem;
					padding-bottom: 1rem;
					padding-right: 1rem;;
				}
			}
			&__right {
				padding: 1rem 1rem 0 1rem;
				.collab-button-parent {
					display: block;
					text-align: right;
					.collab-add-button {
						width: 15rem;
						font-weight: 500;
						font-size: 1rem;
						height: 3rem;
						border-radius: 2rem;
						background-color: #3fcda6;
						color: #f7f7f7;
						margin-top: 2rem;
						line-height: 1rem;
						&:hover {
							background-color: rgb(11, 122, 106);
						}
					}
				}
			}
		}
	}
}

.cases-search {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 2rem;
	transition: 0.65s !important;
	.add-case {
		background: #3fcda6;
		border-radius: 2rem;
		color: #ffffff;
		width: 15rem;
		height: 3.5rem;
		font-weight: 500;
		&:active {
			outline: none !important;
		}
		&:focus {
			outline: none !important;
		}
		&:hover {
			background-color: rgb(11, 122, 106);
		}
	}
}
.input-with-icon {
	border: 1px solid #d6d5d5 !important;
}
.project-tab-selector {
	display: flex;
	width: 40rem;
	height: 3rem;
	line-height: 50px;
	text-align: center;
	align-items: center;

	.tab-option {
		cursor: pointer;
		outline: 0 !important;
		box-shadow: none !important;
		background-color: $unselectedTabBackground;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		border-left: 1px solid;

		&:first-child {
			border-left: none !important;
		}
		&:nth-child(2) {
			width: 42rem;
		}
		.text {
			padding-left: .4rem;
		}
		&.selected {
			background-color: $selectedTabBackground;
			color: $selectedTabTextColor;
			font-weight: bold;
		}
	}
}

.case-table {
	position: relative;
	width: 100%;
	transition: 0.65s;
	padding: 0.125rem 0.125rem 0px 0.125rem;
	.case-item {
		display: grid;
		grid-template-columns: minmax(16rem, auto) minmax(18rem, 18%) 1fr minmax(6rem, 6%) minmax(8rem, 8%) 15rem;
		margin-bottom: 0.9375rem;
		margin-top: 0.9375rem;
		min-height: 8rem;
		background: #ffffff;
		box-shadow: 1px 0px 0.125rem 1px rgba(189, 189, 189, 0.75);
		color: #4b4d53;
		cursor: pointer;
		outline: 0 !important;
		align-items: center;
		position: relative;
		font-size: 0.9rem;
		@media screen and (max-width: 1000px) {
			font-size: 0.7rem;
		}
		.case-item-one {
			display: flex;
			min-width: 0;
			&__name-wrapper {
				width: 100%;
				padding-left: 1.25rem;
				padding-right: 1.25rem;
				.flex-child {
					max-width: 100%;
				}
			}
			.name {
				color: #525252;
				font-weight: bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 1.4rem;
			}
			.owner {
				font-weight: 300;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.case-item-two {
			display: flex;
			flex-direction: column;
			justify-content: center;
			@media screen and (max-width: 1024px) {
				display: none;
			}
			.created {
				color: #ea813d;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.description {
				padding-top: 0.5rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.case-item-file {
			display: flex;
			flex-direction: column;
			.file-item {
			  padding-right: 1rem;
			  display: flex;
			  padding-top: 0.5rem;
			  padding-bottom: 0.5rem;
			  width: 100%;
			  .file-type {
				font-weight: bold;
				width: 8rem;
			  }
			  .file-name {
				margin-left: 0.05rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: $primary-color;
				&:hover {
					text-decoration: underline;
				}
			  }
			}
		  }
		.case-item-summary {
			display: flex;
			justify-content: center;
			width: 100%;
			.review-experiment {
				background: rgba(0, 119, 255, .2);
				border-radius: 100px;
				width: 7rem;
				height: 2.5rem;
				color: rgba(0, 119, 255, 1);
				font-weight: bold;
				&:hover {
					background: rgba(0, 119, 255, 1);
					color: whitesmoke;
				}
			}
		}

		.case-item-status {
			.case-status {
				display: flex;
				flex-direction: column;
				align-items: center;
				p {
					margin-top: 0.5rem !important;
					margin-bottom: 0;
					white-space: nowrap;
				}
				.progress {
					margin: 0.7rem 0 0.7rem 0;
					height: 0.8rem;
					width: 3.75rem;
					background-color: #ddf7f4;
					display: flex;
					align-items: center;
					border-radius: 0.4rem;
					@media screen and (max-width: 1280px) {
						height: 0.8rem;
						width: 2.75rem;
						margin: 0.4rem 0 0.2rem 0;
					}
					.bar {
						background-color: #1aceb3;
						height: 0.8rem;
					}
				}
				.not-finalized-action-button {
					height: 2.2rem;
					width: 2.2rem;
					background-color: #ffcb4d;
					display: flex;
					align-items: center;
					border-radius: 50%;
					&__text {
						width: 100%;
						text-align: center;
						font-weight: 400;
						font-size: 1.5rem;
						color: #ffffff;
					}
					@media screen and (max-width: 1024px) {
						height: 1rem;
						width: 1rem;
					}
				}
				.finalized-action-button {
					height: 2.2rem;
					width: 2.2rem;
					background-color: #3fcda6;
					display: flex;
					align-items: center;
					border-radius: 50%;
					@media screen and (max-width: 1024px) {
						height: 1rem;
						width: 1rem;
					}
					&__text {
						width: 100%;
						text-align: center;
						font-weight: 400;
						font-size: 1.5rem;
						color: #ffffff;
						@media screen and (max-width: 1024px) {
							font-size: .8rem;
						}
					}
				}
			}
		}

		.case-item-buttons {
			border-left: 1px solid #e6e6e6;
			height: 100%;
			display: flex;
			flex-direction: row;
			text-align: right;
			justify-self: right !important;
			align-items: center;
			padding: 0 1rem;
			flex-shrink: 0;
			.action-buttons {
				height: 2.5rem;
				width: 2.5rem;
				border-radius: 50%;
				background-color: #646464;
				margin: 0 .4rem;
				display: flex;
				align-items: center;
				justify-content: center;
				@media screen and (max-width: 1024px) {
					height: 1rem;
					width: 1rem;
				}
				&__text {
					width: 100%;
					text-align: center;
					font-weight: 400;
					font-size: 1.5rem;
					color: #ffffff;
					@media screen and (max-width: 1024px) {
						font-size: .8rem;
					}
				}
			}
			.isclone {
				background: #0076c0;
			}
			.isvisualization {
				background-color: lightgreen;
			}
			.isfavorited {
				background-color: red !important;
			}
			.isshared {
				background: #0303dd;
			}
		}
	}

	.case-item:hover {
		background: #eafbff;
	}
}

.project-modal-share {
	@media screen and (min-width: 1200px) {
		width: 60rem !important;
		max-width: 60rem !important;
	}
}
