.default-setting-card {
	position: relative;
	display: flex;
	flex-direction: column;
  align-items: stretch;
  padding: 1rem 3rem;
  .setting-header {
    font-size: 1.3rem;
	  font-weight: bold;
	  text-align: center;
  }
}

.setting-checkbox {
  margin-top: 1.2rem !important;
}

.setting {
  overflow: visible;
  padding: 0.8rem 0;

  .input-with-default {
    .default-value {
      margin-top: 0.5rem;
      color: gray;
      font-size: 0.8rem;
    }
  }

  input {
    padding-left: 0.5rem;
  }
  
  .setting-name {
    display: inline-block;
    font-size: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
    color: #666;
    &.selected {
      color: $success-color;
    }
  }

  .help-icon {
    height: 1rem;
    width: 1rem;
  }

  .setting-input {
    width: 6.25rem;
  }

  .setting-datepicker {
    width: 7.5rem;
  }
  .compare {
    display: flex;
    align-items: center;
    .compare-select {
      width: 5rem;
      margin-right: 1rem;
    }
    .compare-input {
      width: 10rem;
    }
  }
 
  .selector {
    width: 18.75rem;
  }

  .slider {
    width: 20rem;
  }

  .float-right {
    float: right;
  }

  &.singleline {
    display: flex;
    align-items: center;
    padding: 0.4rem 0;
    .react-datepicker-wrapper {
      float: right;
    }
    .setting-unit {
      float: right;
    }
    .setting-input {
      float: right;
      text-align: right;
    }
    .right {
      flex: 4;
      margin-bottom: 0.5rem;
    }
  }
}