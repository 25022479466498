@import "../colors.scss";

// The main container element
.Collapsible {
  background-color: rgb(255, 255, 255);
}

//The content within the collaspable area
.Collapsible__contentInner {
  padding: 10px;
  border-top: 0;
  padding-left: 1.5rem;
  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.api-header {
  display: block;
  font-weight: 400;
  text-decoration: none;
  opacity: 1;
  color: #999999;
  position: relative;
  border: none !important;
  width: 100%;
  &:focus {
    outline: none !important;
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  opacity: 1;
  color: #999999;
  position: relative;
  border: none !important;
  width: 100%;
  &:focus {
    outline: none !important;
  }

  &:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f078";
    position: absolute;
    right: 10px;
    top: 1px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
      border: none !important;
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.case-title {
  color: #0076c0;
  padding-bottom: 1rem;
}
.no-left-margin {
  margin-left: -1.5rem !important;
}

.transfer-ownership {
  display: flex;
  margin: 1rem 0rem;
  font-size: 0.9rem;
  .transfer-ownership-select {
    width: 12rem;
  }
  .transfer-ownership-button {
    margin-left: 1rem;
    width: 11rem;
  }
}

.case-experiment {
  display: block;
  line-height: 20px;
  font-size: 14px;
  color: #b8b7ad;
  margin-bottom: 0 !important;
  &:last-of-type {
    margin-top: 16px;
  }
  &__input {
    font-size: 16px;
    font-weight: 500;
    padding: 4px 7px;
    width: 85%;
    &:focus {
      outline: none !important;
    }
  }
}

.preprocess-ul {
  padding-inline-start: 0px !important;
}

.case-api-button {
  padding-top: 16px;
  padding-bottom: 16px;
  &__button {
    width: 13rem;
    background: #00caff 0% 0% no-repeat padding-box;
    height: 35px;
    color: white;
    font-size: 1rem;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 3px;
    &:hover {
      background: rgb(0, 172, 217);
    }
  }
}
.score-metric {
  padding-top: 18px;
  font-weight: 600;
  &:first-child {
    padding-top: 0px;
  }
}
.model-score {
  display: flex;
  justify-content: space-between;
  color: #000000;
  opacity: 1;

  .score-left {
    color: #808080;
    padding: 2px 0;
  }
  .score-right {
    padding-right: 1rem;
    color: #0c191e;
    text-align: right;
    padding: 2px 0;
  }
}

.case-data {
  line-height: 20px;
  padding-bottom: 16px;
  display: flex;
  &.multiline {
    display: block;
  }
  &__title {
    font-size: 14px;
    color: #b8b7ad;
    margin-right: 1rem;
  }
  &__text {
    font-size: 16px;
    font-weight: 500;
    &.link {
      color: $primary-color;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.for-scroll {
  padding-bottom: 100px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px !important;
  width: 36px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  box-shadow: 4px 5px 6px #00000029;
  border: 3px solid #efefef;
  @media screen and (min-width: 1200px) {
    width: 25% !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 1.5rem;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white;
}

/* Wrapper for item list */

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
