$product-color: #6A0DAD;
$product-forecolor: #FFFFFF;
$product-secondary-color: #1D588F;
$bright-blue: #007AFF;
// Report colors
$yellow: #F8B715;
$flagyellow: #F0C40F;
$navy: #0D547B;
$dark-grey: #646464;
$light-grey: #b1b1b1;
$grey-blue: #0F5074;

// Universal colors
$primary-color: #637DD7;
$secondary-color: #43425d;
$error-color: #DE7A83;
$success-color: #61B38E;
$warning-color: #ffa600;
$disabled-color: gray;

// Local variables
$primary-text-color: #4d4f5c;
$inverse-text-color: #FFFFFF;

$primary-button-color: $primary-color;
$primary-button-text-color: #FFFFFF;


.error-color { color: $error-color; }
.warning-color { color: $warning-color; }
.primary-color { color: $primary-color; }
.success-color { color: $success-color; }
.disabled-color { color: $disabled-color; }

// $color-order: red, orange, yellow, green, blue, purple;
$color-order: #3FCDA6, #00CAFF, #5A51DD, #F39B12, #F0C40F, #8BC34A;

@keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}