@import "../colors.scss";

.header-outer {
  .product-header {
    background-color: #ffffff;
    color: #fff;
    min-height: 3.75rem;
    border-top: solid 5px $product-color;
    box-shadow: 0 1px 0 rgba(12, 13, 14, 0.1), 0 1px 0.375rem rgba(59, 64, 69, 0.1);
    z-index: 999;
    margin-bottom: 0.1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .logo {
      height: 3rem;
      margin-left: 1rem;
      margin-right: auto;
      cursor: pointer;
    }

    .nav {
      padding: 15px;
      border-right: 1px solid #f2f2f2;
      &.active {
        color: #0076c0;
        text-decoration: underline;
        a {
          color: #0076c0 !important;
        }
      }
      .active {
        color: #0076c0;
        text-decoration: underline;
      }
      .nav-link {
        padding: 0;
      }
      .nav-text {
        &:hover {
          color: orange;
        }
      }
      .help-form {
        width: 22rem;
        color: #000000;
        text-align: left;
        .help-header {
          text-align: center;
          color: #646464;
          padding-bottom: 2rem;
        }
        .primary-button {
          padding: 8px 0;
        }
        &.sent {
          padding: 0;
          margin-bottom: 0;
        }
      }

      a {
        color: #646464;
        // font-family: "Proxima Nova";
        font-size: 15px;
        font-weight: 600;
        line-height: 21px;
      }
    }
  }
}
