.singleline-message {
    display: flex;
    align-items: center;
    margin: 0.3rem 0;
    font-size: 0.8rem;
    .message-icon {
        margin-right: 0.5rem;
    }
    &-lg {
        font-size: 1.1rem;
    }
}
